import { parsePlanId } from 'core/utils/data-utils';
import { apiBaseUrl, deleteData, postData } from 'core/utils/http-utils';
import { HttpQueueWorkerManager } from 'core/workers/http-queue-worker';
import { NO_SESSION_NAME } from '../ui/plan-ui-data-service/process-term-sessions';
import { getIDPAuthToken } from './user-service';

function buildUrl(
  emplid: string,
  {
    planId,
    termCode,
    sessionCode,
    courseId,
    isRequirement,
  }: API.PlanData.ClassMoveUrlParams,
) {
  const subPath = isRequirement ? 'requirements' : 'courses';
  const url =
    `/api/${emplid}/plans/${parsePlanId(planId)}/terms/${termCode}` +
    `/sessions/${sessionCode || NO_SESSION_NAME}/${subPath}/${courseId}`;

  return url;
}

async function updatePlanCourses(
  emplid: string,
  planId: string,
  dragItem: ClassDragItemData,
  planWorker: HttpQueueWorkerManager,
) {
  const { target, source } = dragItem;

  const isRequirement = source.isRequirement,
    courseType = source.status,
    creditHours = source.creditHours;

  const url = buildUrl(emplid, {
    planId,
    isRequirement,
    termCode: source.termCode,
    sessionCode: source.sessionCode,
    courseId: source.cardId,
  });

  const payload = {
    courseType: isRequirement ? undefined : courseType,
    creditHours: isRequirement ? creditHours : undefined,
    // target
    newTermCode: target.termCode,
    newTermName: target.termName,
    newSessionCode: target.sessionCode,
  };

  const jwt = getIDPAuthToken();

  planWorker.enqueueRequest({
    method: 'PUT',
    url: `${apiBaseUrl}${url}`,
    authorization: `Bearer ${jwt?.token}`,
    data: payload,
    tags: {
      dragItem,
    },
  });
}

async function createPlanCourse(
  emplid: string,
  planId: string,
  payload: API.PlanData.ClassCreatePayload,
) {
  const url = `api/${emplid}/plans/${planId}/course`;
  const response = await postData<API.HttpPlanAnyResponse>(url, payload);

  return response;
}

async function deletePlanCourse(
  emplid: string,
  planId: string,
  payload: API.PlanData.ClassDeleteUrlParams,
) {
  const { termCode, sessionCode, subject, catalogNumber } = payload;

  const courseId = `${termCode}:${sessionCode}:${subject}:${catalogNumber}`;
  const url = `api/${emplid}/plans/${planId}/course/${courseId}`;

  const response = await deleteData<API.HttpPlanAnyResponse>(url);

  return response;
}

export { createPlanCourse, deletePlanCourse, updatePlanCourses };
