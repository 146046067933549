import { fetchData, postData } from '../../utils/http-utils';

function buildUrl(emplid: string) {
  return `/api/${emplid}/profile`;
}

async function getStudentProfile(emplid: string) {
  const url = buildUrl(emplid);
  const { data } = await fetchData<API.HttpStudentProfileGetResponse>({
    url,
    cache: 'force-cache',
  });

  return data;
}

async function saveStudentProfile(
  prefInfo: API.StudentData.PreferredCommunication,
  emplid: string,
) {
  const url = buildUrl(emplid);
  const response = await postData<
    API.HttpStudentProfilePostResponse,
    API.HttpStudentProfilePostPayload
  >(url, {
    preferredCommunicationAlternatePhone: prefInfo.alternatePhone,
    preferredCommunicationAlternateEmail: prefInfo.alternateEmail,
    preferredCommunicationComMode: prefInfo.mode,
    preferredCommunicationTimeZone: prefInfo.timeZone,
    preferredCommunicationStartTime: prefInfo.startTime,
    preferredCommunicationEndTime: prefInfo.endTime,
  });

  return response;
}

export { getStudentProfile, saveStudentProfile };
