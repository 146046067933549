import { FC } from 'react';

type Props = {
  className?: string;
  children: string | string[];
};

const ErrorMessage: FC<Props> = ({ className, children }) => {
  const messages = Array.isArray(children) ? children : [children];

  return (
    <div
      className={`${className} text-error mt-2 flex gap-2 text-sm font-bold`}
    >
      <i title="Alert" className="fa fa-icon fa-exclamation-triangle" />
      <div className="flex flex-col gap-1 leading-none">
        {messages.map((msg) => (
          <div key={msg}>{msg}</div>
        ))}
      </div>
    </div>
  );
};

export { ErrorMessage };
