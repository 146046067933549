// @ts-check

function getSSOName() {
  try {
    let cookies = decodeURIComponent(document.cookie).split('; ');
    const ssoNameCookie = cookies.find((c) => c.includes('SSONAME'));

    return ssoNameCookie ? ssoNameCookie.split('=')?.[1] : '';
  } catch (error) {
    return '';
  }
}

export { getSSOName };
