import { useEffect } from 'react';

const docStyle = document.documentElement.style;

const useSetCssHeightProperty = ({
  cssPropName,
  refNode,
}: {
  cssPropName: string;
  refNode: React.RefObject<HTMLElement>;
}) => {
  useEffect(() => {
    const elementHeight = refNode.current?.clientHeight || 0;
    docStyle.setProperty(cssPropName, elementHeight + 'px');

    return () => {
      docStyle.setProperty(cssPropName, '0px');
    };
  }, [cssPropName, refNode]);
};

const useSetCssWidthProperty = ({
  cssPropName,
  refNode,
}: {
  cssPropName: string;
  refNode: React.RefObject<HTMLElement>;
}) => {
  useEffect(() => {
    const element = refNode.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        const elementWidth = entry.contentBoxSize[0].inlineSize;
        docStyle.setProperty(cssPropName, elementWidth + 'px');
      }
    });

    resizeObserver.observe(element);

    return () => {
      docStyle.setProperty(cssPropName, '0px');

      resizeObserver.unobserve(element);
    };
  }, [cssPropName, refNode]);
};

export { useSetCssHeightProperty, useSetCssWidthProperty };
