import { useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { RoleName } from 'core/constants/app-constants';
import { appRouterUrl, startPageByRole } from 'core/constants/router-url';
import planActiveReducer from './slices/planActiveSlice';
import planSetupReducer from './slices/planSetupSlice';
import planReducer from './slices/planSlice';
import sharedReducer from './slices/sharedSlice';
import studentProfileReducer from './slices/studentProfileSlice';
import termsSliceReducer from './slices/termsSlice';
import userProfileReducer from './slices/userProfileSlice';
import { pickCurrentRoleSelector } from './slices/utils';

// Set up store
const setupStore = (preloadedState?: Partial<AppState>) => {
  return configureStore({
    reducer: {
      userProfile: userProfileReducer,
      studentProfile: studentProfileReducer,
      plan: planReducer,
      planSetup: planSetupReducer,
      planActive: planActiveReducer,
      shared: sharedReducer,
      terms: termsSliceReducer,
    },
    preloadedState,
  });
};

const store = setupStore();
store.getState();

// # Utilities to handle the state
type AppDispatch = typeof store.dispatch;

// ## dispatch an action
const useAppDispatch: () => AppDispatch = useDispatch;

// ## select the app state
type RootState = ReturnType<typeof store.getState>;
const useAppSelector = <TState = RootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
) => {
  return useSelector(selector);
};

// ## select a slice of contactInfo
const useContactInfoSelector = (
  key: StringKeyOf<Required<API.StudentData.Profile>>,
) => {
  return useAppSelector((state) => state.studentProfile.data[key!] as string);
};
const useContactPreferredCommunication = (
  key: keyof API.StudentData.PreferredCommunication,
) => {
  return useAppSelector(
    (state) =>
      state.studentProfile.data.preferredCommunication?.[key] as string,
  );
};
// ## select a slice of contactInfo
const useContactValidatorSelector = (
  key: keyof StudentProfileState['validator'],
) => {
  return useAppSelector((state) => state.studentProfile.validator[key]);
};
// ## select the current role
const useCurrentRoleSelector = () => useAppSelector(pickCurrentRoleSelector);

const useDashboardUrlSelector = () =>
  useAppSelector((state) => {
    const role =
      state.userProfile.viewAs.currentRole || state.userProfile.currentRole;

    const dashboard = role
      ? startPageByRole[role] || appRouterUrl.NOT_FOUND
      : appRouterUrl.NOT_FOUND;

    return dashboard;
  });

const useUserRolesSelector = (authRoles: string[] = []) =>
  useAppSelector((state) => {
    const viewAsRoles = state.userProfile?.viewAs?.selectedUser?.roles;
    const profileRoles = state.userProfile.roles;

    if (viewAsRoles && viewAsRoles.length > 0) return viewAsRoles;
    if (profileRoles && profileRoles.length > 0) return profileRoles;
    if (authRoles && authRoles.length > 0) return authRoles;
    return [];
  });

const useCurrentProfile = (useSearchProfile: boolean = false) =>
  useAppSelector((state) => {
    // TODO: to extends with major types?
    const majorTypes: Record<string, string> = {
      MAJ: 'Major',
    };

    const { search, viewAs } = state.userProfile;

    let profile: EveryProfile;

    if (useSearchProfile) {
      profile = search.selectedUser?.profile;
    } else if (viewAs.active) {
      profile = viewAs.selectedUser?.profile;
    } else {
      profile = state.studentProfile.data;
    }

    const major =
      profile?.declaredDegreePrograms.declaredMajors?.[0] ||
      ({
        name: '',
        degree: '',
        type: '',
        campusName: '',
        academicPlanCode: undefined,
      } satisfies Partial<API.StudentData.DegreeProgram>);

    const majorName =
      major.name && major.degree ? `${major.name}, ${major.degree}` : '';
    const majorType = majorTypes[major.type] || 'Major';

    return {
      profile,
      major,
      majorName,
      majorType,
    };
  });

const usePlanExtraDataSelector = () =>
  useAppSelector((state) => {
    const lastConsumerEmplid = state.plan.lastConsumerEmplid;
    const myEmplid = state.userProfile.emplid;
    const currentRole =
      state.userProfile.viewAs.currentRole || state.userProfile.currentRole;

    const ownedByCurrentUser = lastConsumerEmplid === myEmplid;
    const isReadOnlyRole =
      currentRole === RoleName.ADVISOR || currentRole === RoleName.COACH;

    const readonly = ownedByCurrentUser === false && isReadOnlyRole;

    return { ownedByCurrentUser, readonly };
  });

const usePlanWarningsSelector = () =>
  useAppSelector((state) => {
    const planData = state.plan.data;
    const planWarnings =
      planData?._uiMetaData?.validator?.failureWarnings || [];

    return planWarnings;
  });

export {
  store,
  setupStore,
  useAppSelector,
  useAppDispatch,
  useContactInfoSelector,
  useContactPreferredCommunication,
  useContactValidatorSelector,
  useCurrentRoleSelector,
  useCurrentProfile,
  useDashboardUrlSelector,
  usePlanExtraDataSelector,
  usePlanWarningsSelector,
  useUserRolesSelector,
};
