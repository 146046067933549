import { fetchData } from 'core/utils/http-dpl-utils';

async function getCurrentTerm() {
  const url = 'api/codeset/term/current';
  const { data } = await fetchData<DPL_API.HttpTermGetResponse>({
    url,
  });

  return data;
}

async function getTermByRange(termCodeRanges: string[]) {
  const url = `api/codeset/terms?strmRange=${termCodeRanges.join('-')}`;
  const { data } = await fetchData<DPL_API.HttpTermRangeGetResponse>({
    url,
  });

  return data;
}

async function getTerm(strm: string) {
  const url = `api/codeset/terms?strm=${strm}`;
  const { data } = await fetchData<DPL_API.HttpTermGetResponse>({
    url,
  });

  return data;
}

export { getCurrentTerm, getTermByRange, getTerm };
