import React, { FC } from 'react';
import { SpinnerLoader } from '../Loaders';

type Props = {
  className?: string;
  containerTag?: keyof JSX.IntrinsicElements;
  loaderMessage?: string | null;
  children: React.ReactNode;
};

const BoxLoader: FC<Props> = ({
  containerTag = 'div',
  className,
  loaderMessage,
  children,
}) => {
  const Container = containerTag;

  return (
    <Container className={loaderMessage ? 'relative h-full' : className}>
      {loaderMessage ? (
        <SpinnerLoader
          spinnerMessage={loaderMessage}
          size="20px"
          attachContainer="element"
          fullOpacity
        />
      ) : (
        children
      )}
    </Container>
  );
};

export { BoxLoader };
