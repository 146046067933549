import { FC, Suspense } from 'react';
import { SpinnerLoader } from '../Loaders';

type Props = {
  children: React.ReactNode;
};

const LazyRoute: FC<Props> = ({ children }) => {
  return (
    <Suspense
      fallback={<SpinnerLoader spinnerMessage="Page loading..." fullSize />}
    >
      {children}
    </Suspense>
  );
};

export { LazyRoute };
