import { useMemo } from 'react';

function useMakeId(prefix = '', range = 1000) {
  const id = useMemo(
    () => `${prefix}${String(Math.random() * range).replace('.', '-')}`,
    [prefix, range],
  );

  return id;
}

export { useMakeId };
