import { FC } from 'react';

import styles from './index.module.scss';

const WheelTemplate = ({ className = '', size = '40px' }) => (
  <div
    id="wheel-spinner"
    className={`${styles['wheel-container']} ${className}`}
    style={{
      width: size,
      height: size,
    }}
  />
);

const CircleTemplate = ({ className = '', size = '48px' }) => (
  <svg
    id="circle-spinner"
    className={`${styles['circle-container']} ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 48 48"
  >
    <circle cx="24" cy="4" r="4" fill="#fff" />
    <circle cx="12.19" cy="7.86" r="3.7" fill="#fffbf2" />
    <circle cx="5.02" cy="17.68" r="3.4" fill="#fef7e4" />
    <circle cx="5.02" cy="30.32" r="3.1" fill="#fef3d7" />
    <circle cx="12.19" cy="40.14" r="2.8" fill="#feefc9" />
    <circle cx="24" cy="44" r="2.5" fill="#feebbc" />
    <circle cx="35.81" cy="40.14" r="2.2" fill="#fde7af" />
    <circle cx="42.98" cy="30.32" r="1.9" fill="#fde3a1" />
    <circle cx="42.98" cy="17.68" r="1.6" fill="#fddf94" />
    <circle cx="35.81" cy="7.86" r="1.3" fill="#fcdb86" />
  </svg>
);

type Props = {
  className?: string;
  spinnerType?: 'circle' | 'wheel';
  spinnerMessage?: string | string[] | React.ReactNode;
  size?: string;
  attachContainer?: 'page' | 'element';
  fullSize?: boolean;
  fullOpacity?: boolean;
  frameInnerContent?: boolean;
};

const parseSpinnerMessage = (spinnerMessage: Props['spinnerMessage']) => {
  // array of string
  if (Array.isArray(spinnerMessage)) {
    return (
      <p id="text-spinner">
        {spinnerMessage.map((msg, index) => (
          <span key={msg}>
            {msg}
            <br />
          </span>
        ))}
      </p>
    );
  }
  // react element
  if (typeof spinnerMessage === 'object') {
    return spinnerMessage;
  }
  // plain string
  return <p id="text-spinner">{spinnerMessage}</p>;
};

const SpinnerLoader: FC<Props> = ({
  className,
  spinnerType = 'circle',
  spinnerMessage = [],
  size = '30px',
  fullSize,
  fullOpacity,
  frameInnerContent = false,
  attachContainer = 'page',
}) => {
  const marginClass = spinnerMessage ? 'mb-4' : '';

  return (
    <div
      className={`${styles.container} ${className}`}
      data-full-size={fullSize}
      data-attach-container={attachContainer}
      data-full-opacity={fullOpacity}
    >
      <div
        className={styles.content}
        data-frame-inner-content={frameInnerContent}
      >
        {spinnerType === 'wheel' && (
          <WheelTemplate className={marginClass} size={size} />
        )}
        {spinnerType === 'circle' && (
          <CircleTemplate className={marginClass} size={size} />
        )}

        {parseSpinnerMessage(spinnerMessage)}
      </div>
    </div>
  );
};

export {
  SpinnerLoader,
  type Props as SpinnerLoaderProps,
  CircleTemplate as CircleLoader,
  WheelTemplate as WheelLoader,
};
