/// <reference path="./index.d.ts" />

import { FC, useState } from 'react';

import { PageItem } from './components/PageItem';
import { PageList } from './components/PageList';
import styles from './index.module.scss';

const isButtonDisabled = (e: MouseClickEvent) =>
  e.currentTarget.getAttribute('disabled');

type Props = {
  className?: string;
  /**
   * If true, the component is disabled.
   */
  disabled: boolean;
  /**
   * Number of always visible pages before and after the current page.
   * @default {1}
   */
  siblingCount?: number;
  /**
   * Number of always visible pages at the beginning and end.
   * @default {1}
   */
  boundaryCount?: number;
  /**
   * The total number of pages.
   */
  count: number;
  /**
   * The total number rows per page.
   */
  pageSize?: number;
  onChange: (pageIndex: number, pageNumber: number) => void;
};

const boundaryItemClassName = 'gap-1 font-bold';

const Pagination: FC<Props> = ({
  className,
  disabled,
  count,
  pageSize,
  siblingCount = 1,
  boundaryCount = 1,
  onChange = () => null,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = pageSize ? Math.ceil(count / pageSize) : count;

  const hasPaging = totalPages > 1;
  const isLastPage = !hasPaging || currentPage === totalPages;

  const isPrevDisabled = !hasPaging || disabled || currentPage === 1;
  const isNextDisabled = !hasPaging || disabled || isLastPage;
  const isLastDisabled = !hasPaging || currentPage === totalPages;

  const pageChange = (newPage: number) => {
    setCurrentPage(newPage);
    onChange(newPage - 1, newPage);
  };

  const moveFirst = (e: MouseClickEvent) => {
    if (isButtonDisabled(e)) return;
    pageChange(1);
  };

  const movePrev = (e: MouseClickEvent) => {
    if (isButtonDisabled(e)) return;
    if (currentPage === 1) return;
    pageChange(currentPage - 1);
  };

  const moveNext = (e: MouseClickEvent) => {
    if (isButtonDisabled(e)) return;
    pageChange(currentPage + 1);
  };

  const moveLast = (e: MouseClickEvent) => {
    if (isButtonDisabled(e)) return;
    pageChange(totalPages);
  };

  const moveTo = (page: PagePosition) => {
    const pagePosition: Record<PagePosition, number> = {
      first: 1,
      prev: currentPage === 1 ? 1 : currentPage - 1,
      next: currentPage === totalPages ? totalPages : currentPage + 1,
      last: totalPages,
      '...': -1,
    };

    const newPage = pagePosition[page] ?? page;
    pageChange(newPage);
  };

  return (
    <nav className={`${className} ${styles.container} flex items-center`}>
      <ul className="flex h-fit w-full items-center justify-between gap-x-2 py-1">
        <PageItem
          className={boundaryItemClassName}
          disabled={isPrevDisabled}
          onClick={moveFirst}
        >
          First
        </PageItem>

        <PageItem
          className={boundaryItemClassName}
          disabled={isPrevDisabled}
          onClick={movePrev}
        >
          <i title="Previous" className="fa-solid fa-angle-left" />
          <span>Prev</span>
        </PageItem>

        {totalPages > 0 ? (
          <li>
            <PageList
              totalPages={totalPages}
              currentPage={currentPage}
              onChange={moveTo}
              siblingCount={siblingCount}
              boundaryCount={boundaryCount}
            />
          </li>
        ) : (
          <PageItem disabled>0</PageItem>
        )}

        <PageItem
          className={boundaryItemClassName}
          disabled={isNextDisabled}
          onClick={moveNext}
        >
          <span>Next</span>
          <i title="Next" className="fa-solid fa-angle-right" />
        </PageItem>

        <PageItem
          className={boundaryItemClassName}
          disabled={isLastDisabled}
          onClick={moveLast}
        >
          Last
        </PageItem>
      </ul>
    </nav>
  );
};

export { Pagination };
