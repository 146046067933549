/* eslint-disable react/no-array-index-key */
/// <reference path="../index.d.ts" />

import { FC } from 'react';

import styles from '../index.module.scss';
import { createRange, iff } from '../utils';
import { PageItem } from './PageItem';

type Props = {
  totalPages: number;
  currentPage: number;
  siblingCount: number;
  boundaryCount: number;
  onChange: (page: PagePosition) => void;
};

function createDisplayPageRange(currentPage: number, totalPages: number) {
  const lowerRangeLimit = iff(
    currentPage === totalPages - 1,
    2,
    currentPage === totalPages ? 3 : 1,
  );
  const upperRangeLimit = iff(currentPage === 1, 3, currentPage === 2 ? 2 : 1);

  const lowerRange = createRange(
    currentPage - lowerRangeLimit,
    currentPage,
    totalPages,
  );
  const upperRange = createRange(
    currentPage,
    currentPage + 1 + upperRangeLimit,
    totalPages,
  );

  return {
    lowerRange,
    upperRange,
  };
}

const PageList: FC<Props> = ({
  currentPage,
  siblingCount,
  totalPages,
  onChange,
}) => {
  const { lowerRange, upperRange } = createDisplayPageRange(
    currentPage,
    totalPages,
  );
  const pageNumbers = [...lowerRange, ...upperRange];
  const firstPageNumber = pageNumbers[0];
  const lastPageNumber = pageNumbers[pageNumbers.length - 1];

  const activeClassName = 'bg-primary text-white';

  const pages = pageNumbers.map<{
    value: PagePosition;
    isCurrent?: boolean;
    disabled?: boolean;
  }>((page) => ({
    value: page,
    isCurrent: currentPage === page && totalPages > 1,
    disabled: totalPages === 1,
  }));

  if (firstPageNumber > 2) {
    pages.unshift({
      value: '...',
      disabled: true,
    });
  }

  if (firstPageNumber !== 1) {
    pages.unshift({
      value: 1,
    });
  }

  if (lastPageNumber < totalPages - 1) {
    pages.push({
      value: '...',
      disabled: true,
    });
  }

  if (lastPageNumber !== totalPages) {
    pages.push({
      value: totalPages,
    });
  }

  return (
    <ul
      id={styles['list-page-number']}
      className="flex w-fit items-center justify-center gap-x-2"
    >
      {pages.map((page, index) => (
        <PageItem
          key={page.value + '-' + index}
          shape="circular"
          className={`aspect-square ${page.isCurrent ? activeClassName : ''}`}
          disabled={page.disabled}
          data-current={page.isCurrent}
          aria-label={`Page ${page} of ${totalPages}`}
          onClick={(e) => onChange(page.value)}
        >
          {page.value}
        </PageItem>
      ))}
    </ul>
  );
};

export { PageList };
