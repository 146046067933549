import React, { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
} & React.ComponentPropsWithRef<'div'>;

const Card = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className = '', ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`${styles.container} ${className} flex w-full justify-between py-3 pl-4 pr-12 text-sm leading-none`}
        data-card
        {...props}
      >
        {children}
      </div>
    );
  },
);

export { CardList } from './CardList';
export { Card };
