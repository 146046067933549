import { createContext, FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'core/auth';
import { RoleSwitch } from 'core/components';
import { startPageByRole } from 'core/constants/router-url';
import {
  getIDPClaimToken,
  recoverCurrentRole,
} from 'core/services/idp/user-service';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentRoleSelector,
} from 'core/store';
import {
  setDeviceOffline,
  setDeviceTryReconnect,
  setShowNavbar,
} from 'core/store/slices';
import { getCurrentTermAsync } from 'core/store/slices/planSlice';
import { getStudentProfileAsync } from 'core/store/slices/studentProfileSlice';
import {
  resumeUser,
  setCurrentRole,
  setPrimaryRole,
  setUserEmplid,
  setUserPermissions,
  setUserRoles,
  setViewAsFromStorage,
  setViewAsRole,
} from 'core/store/slices/userProfileSlice';
import { pickPrimaryRole } from 'core/store/slices/utils';

const initialState = {};
const AppContext = createContext(initialState);

type Props = {
  children: React.ReactElement;
};

const AppProvider: FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    state: { userRoles, isAuthenticated },
  } = useAuthContext();

  useEffect(() => {
    dispatch(getCurrentTermAsync());
  }, [dispatch]);

  useEffect(() => {
    const claim = getIDPClaimToken();
    const currentRole = recoverCurrentRole() || null;
    const roles = userRoles?.roles || [];
    const primaryRole = pickPrimaryRole(roles);

    if (currentRole && roles.includes(currentRole)) {
      dispatch(setCurrentRole(currentRole));
    } else {
      dispatch(setCurrentRole(primaryRole));
    }

    dispatch(setUserEmplid(claim?.emplid || ''));
    dispatch(setUserRoles(roles));
    dispatch(setPrimaryRole(primaryRole));
    dispatch(setUserPermissions(claim?.scope || []));
    dispatch(setViewAsFromStorage());
  }, [dispatch, userRoles?.roles]);

  useEffect(() => {
    const claim = getIDPClaimToken();

    if (isAuthenticated && userRoles?.completed && claim?.emplid) {
      dispatch(getStudentProfileAsync());
    }
  }, [dispatch, isAuthenticated, userRoles?.completed]);

  useEffect(() => {
    const offlinePage = () => {
      dispatch(setDeviceOffline(true));
    };

    const onlinePage = () => {
      dispatch(setDeviceTryReconnect(false));
      dispatch(setDeviceOffline(false));
    };

    window.addEventListener('offline', offlinePage);
    window.addEventListener('online', onlinePage);

    return () => {
      window.removeEventListener('offline', offlinePage);
      window.removeEventListener('onlinePage', onlinePage);
    };
  }, [dispatch]);

  const currentRole = useCurrentRoleSelector();
  const viewAs = useAppSelector((state) => state.userProfile.viewAs);
  const viewAsActive = viewAs.active;
  const selectedRoles = viewAs.selectedUser?.roles;
  const resumePage = viewAs.startPage || '';
  const preferredName = viewAs.selectedUser?.profile.preferredName || '';

  return (
    <AppContext.Provider value={{}}>
      {children}

      {viewAsActive && currentRole && (
        <RoleSwitch
          userName={preferredName}
          currentRole={currentRole}
          roles={selectedRoles}
          onRoleChange={(roleName) => {
            dispatch(setViewAsRole(roleName));
            navigate(startPageByRole[roleName]);
          }}
          onResumeUser={() => {
            dispatch(resumeUser());
            dispatch(getStudentProfileAsync());
            dispatch(setShowNavbar(true));
            navigate(resumePage);
          }}
        />
      )}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContext, AppProvider, useAppContext };
