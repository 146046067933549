function computeAnimationSettings(svg: SVGSVGElement) {
  const svgPaths = svg.querySelectorAll('path');
  let prevAnimationDuration = 0;

  svgPaths.forEach((path: SVGPathElement, i) => {
    const valuePercent = Number(path.dataset.value);
    const pathLength = path.getTotalLength();
    const animationDuration = 50 + (1000 * valuePercent) / 100;
    const animationDelay = prevAnimationDuration - prevAnimationDuration * 0.2;
    prevAnimationDuration += animationDuration;

    path.style.setProperty('--path-length', String(pathLength));
    path.style.setProperty('--animation-duration', `${animationDuration}ms`);
    path.style.setProperty('--animation-delay', `${animationDelay}ms`);
  });
}

export { computeAnimationSettings };
export * from './path-utils';
