import { ElementRef, FC, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './index.module.scss';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  size?: `${number}${string}` | `inherit`;
  offsetTop?: number;
  offsetLeft?: number;
  [x: `data-${string}`]: any;
} & (
  | {
      iconClassName: string;
      renderIcon?: never;
    }
  | {
      iconClassName?: never;
      renderIcon: React.ReactNode;
    }
);

const Tooltip: FC<Props> = ({
  children,
  iconClassName,
  renderIcon,
  disabled = false,
  size = 'inherit',
  offsetTop = 0,
  offsetLeft = 0,
  ...props
}) => {
  const contentRef = useRef<ElementRef<'span'>>(null);

  const hideTooltip = () => {
    contentRef.current!.style.setProperty('opacity', '0');
  };

  return (
    <span
      className={`${
        styles.container
      } relative flex items-center justify-center ${
        disabled ? 'text-gray-500' : 'text-gray-700'
      }`}
      style={{
        ['--size' as string]: size,
      }}
      data-disabled={disabled}
      onMouseLeave={hideTooltip}
      {...props}
    >
      <span
        className="flex"
        onMouseOver={(e) => {
          const { height, y, width, x } =
            e.currentTarget.getBoundingClientRect();

          const top = y + height + offsetTop + 'px';
          const left = x + width + 'px';
          // const top = y + height + offsetTop + 'px';
          // const left = x + width + offsetLeft + 'px';
          debugger;
          contentRef.current!.style.setProperty('top', top);
          contentRef.current!.style.setProperty('left', left);
          contentRef.current!.style.setProperty('opacity', '1');
        }}
      >
        {iconClassName ? <i className={iconClassName} /> : renderIcon}
      </span>

      {createPortal(
        <span
          ref={contentRef}
          id={styles.content}
          className="shadow-base fade-in z-2 absolute bg-white p-4 tracking-tighter"
          style={{ top: offsetTop + 'px', left: offsetLeft + 'px' }}
          onMouseLeave={hideTooltip}
        >
          {children}
        </span>,
        document.body,
        'tooltip',
      )}
    </span>
  );
};

export { Tooltip };
