function sumTransferCredits(creditList: API.PlanData.TransferCreditTerm[]) {
  const total = creditList.reduce((accumulator, current) => {
    const subTotal = current.classes.reduce(
      (sum, { creditHours }) => sum + creditHours,
      0,
    );
    return accumulator + subTotal;
  }, 0);

  return total;
}

function processTransferCredits(transferCredits: API.PlanData.TransferCredits) {
  const courseCreditHours = sumTransferCredits(transferCredits.courseCredits);
  const testCreditHours = sumTransferCredits(transferCredits.testCredits);
  const transferCreditHours = courseCreditHours + testCreditHours;

  transferCredits._uiMetaData = {
    courseCreditHours,
    testCreditHours,
    transferCreditHours,
  };

  return transferCredits;
}

export { processTransferCredits };
