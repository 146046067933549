import { toDateWithMonthDay } from 'core/utils/date-utils';

const defaultSessionMap = {
  A: 'A',
  B: 'B',
  C: 'C',
} as const;

const NO_SESSION_NAME = 'TBD';
/** Online student */
const DYN = 'DYN';

const sessionKeyMap = {
  ...defaultSessionMap,
  DYN: 'Other',
} as const;

const isNoneSession = (sessionName: string | null | undefined) => {
  return (
    sessionName === null ||
    sessionName === undefined ||
    sessionName === NO_SESSION_NAME
  );
};

function createSessionSchema(
  term: API.PlanData.Term,
  termMap: DPL_API.TermData.TermMap | null,
) {
  const termCode = term.term;
  // =====================================================
  // sessions
  // =====================================================
  const sessionList: typeof term.sessions = [];
  // sessions -> attach missing default session
  for (let sessionName in defaultSessionMap) {
    const found = term.sessions.find((s) => s.sessionName === sessionName);
    sessionList.push(
      found || {
        sessionName,
        classes: [],
        requiredClasses: [],
        selectedClasses: [],
      },
    );
  }

  // sessions -> sort session by name
  sessionList.sort((a, b) => a.sessionName!?.localeCompare(b.sessionName!));

  // sessions -> session Other
  const otherSession = term.sessions.find((s) => s.sessionName === DYN);
  otherSession && sessionList.push(otherSession);

  const noSessionList = term.sessions.filter((s) =>
    isNoneSession(s.sessionName),
  );

  if (noSessionList.length > 0) {
    const noSessionFlatList: API.PlanData.Session = {
      sessionName: null,
      requiredClasses: noSessionList.flatMap((s) => s.requiredClasses || []),
      selectedClasses: noSessionList.flatMap((s) => s.selectedClasses || []),
      classes: noSessionList.flatMap((s) => s.classes || []),
    };
    sessionList.push(noSessionFlatList);
  }
  // =====================================================
  const sessionMap = termMap?.[termCode]?.careerMap?.UGRD?.sessionMap;
  // =====================================================
  return {
    sessionList,
    sessionMap,
  };
}

function processSessionDates(
  session: API.PlanData.Session,
  sessionData: DPL_API.TermData.SessionMapItem | null,
) {
  if (!sessionData) {
    console.warn(`session "${session.sessionName}" has no SessionMap dates`);
    return;
  }

  const {
    dropDeadlineDate,
    withdrawalDeadlineDate,
    completeSessionWithdrawalDeadlineDate,
  } = sessionData;

  const sysDate = Date.now();

  // =====================================================
  // course drop/withdrawal deadlines
  // =====================================================
  let sessionDropLabel = 'Drop deadline has passed';
  const lastDay = toDateWithMonthDay(dropDeadlineDate);

  let allowDropWithdrawal = true;
  let courseDropLabel = '';
  let classDropPlanned = false;

  if (sysDate <= dropDeadlineDate) {
    courseDropLabel = 'Class drop planned';
    sessionDropLabel = `Last day to drop: ${lastDay}`;
    classDropPlanned = true;
  } else if (sysDate > dropDeadlineDate && sysDate <= withdrawalDeadlineDate) {
    courseDropLabel = 'Withdrawal planned';
  } else if (
    sysDate > withdrawalDeadlineDate &&
    sysDate <= completeSessionWithdrawalDeadlineDate
  ) {
    courseDropLabel = 'Withdrawal planned';
  } else if (sysDate > completeSessionWithdrawalDeadlineDate) {
    allowDropWithdrawal = false;
  }

  if (session.sessionName === DYN) {
    session._uiMetaData = {
      ...session._uiMetaData!,
      dropDeadlineInfo: {
        ...session._uiMetaData!.dropDeadlineInfo!,
        courseDropLabel,
        classDropPlanned,
      },
      allowDropWithdrawal,
    };
  } else {
    session._uiMetaData = {
      ...session._uiMetaData!,
      startDate: sessionData.sessionBeginDate,
      endDate: sessionData.sessionEndDate,
      lastEnrollmentDate: sessionData.lastEnrollmentDate,
      dropDeadlineInfo: {
        lastDay,
        sessionDropLabel,
        courseDropLabel,
        classDropPlanned,
      },
      allowDropWithdrawal,
    };
  }
}

function processSessionRestrictions(
  session: API.PlanData.Session,
  sessionData: DPL_API.TermData.SessionMapItem | null,
) {
  if (!sessionData) return;

  const { lastEnrollmentDate } = sessionData;

  const sysDate = Date.now();

  let { lastEnrollmentDatePassed, allowMoveInSession, sessionCode } =
    session._uiMetaData!;

  // Prevent D&D to session where add deadline has passed
  if (sysDate > lastEnrollmentDate) {
    lastEnrollmentDatePassed = true;
    allowMoveInSession = false;
  }
  // Prevent D&D to "Other/DYN/TBD" session
  if (sessionCode === DYN) {
    allowMoveInSession = false;
  }

  session._uiMetaData = {
    ...session._uiMetaData!,
    allowMoveInSession,
    lastEnrollmentDatePassed,
  };
}

function getAllSessionClasses(session: API.PlanData.Session) {
  const { classes = [], selectedClasses = [], requiredClasses } = session;
  const classElements = [...classes, ...selectedClasses, ...requiredClasses];

  return classElements;
}

export {
  createSessionSchema,
  getAllSessionClasses,
  processSessionDates,
  processSessionRestrictions,
  defaultSessionMap,
  sessionKeyMap,
  NO_SESSION_NAME,
};
