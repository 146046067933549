import React, { FC } from 'react';

type Props = React.FormHTMLAttributes<HTMLFormElement> & {
  onKeyEnterDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined;
};

const Form: FC<Props> = ({ children, onKeyEnterDown, ...props }) => {
  return (
    <form
      noValidate
      method="post"
      onSubmit={(event) => event.preventDefault()}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          event.preventDefault();
          event.stopPropagation();
          onKeyEnterDown?.(event);
          return false;
        }
      }}
      {...props}
    >
      {children}
    </form>
  );
};

export { Form };
