import { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  size?: `${number}${string}` | `inherit`;
  overStyleDisabled?: boolean;
  [x: `data-${string}`]: any;
} & (
  | {
      iconClassName: string;
      renderIcon?: never;
    }
  | {
      iconClassName?: never;
      renderIcon: React.ReactNode;
    }
);

const IconTooltip: FC<Props> = ({
  children,
  className = '',
  iconClassName,
  renderIcon,
  disabled = false,
  size = 'inherit',
  overStyleDisabled = false,
  ...props
}) => {
  return (
    <span
      className={`
        ${className} ${styles.container}
        relative isolate inline-flex items-center justify-center leading-none
        ${disabled ? 'text-gray-500' : 'text-gray-700'}`}
      style={{ ['--size' as string]: size }}
      data-disabled={disabled}
      data-hover-style-disabled={overStyleDisabled}
      {...props}
    >
      <span>
        {iconClassName ? <i className={iconClassName} /> : renderIcon}
      </span>

      <span
        data-element="content"
        className={
          'shadow-base z-200 fade-in top-100 absolute left-0 ' +
          'inline-block w-max bg-white p-1 tracking-tighter'
        }
      >
        {children}
      </span>
    </span>
  );
};

export { IconTooltip };
