import { appRouterUrl } from './router-url';

const env = process.env;
// ============================================================================
// webapp auth settings
// ============================================================================
const defaultLoginUrl = '/login';
const defaultRedirectUrl = window.location.origin;
const defaultClientId = `interactive-degree-planner-public-nonprod`;
// ============================================================================
// scopes
// ============================================================================
const defaultScopes = [
  'https://interactive-degree-planner-nonprod.apps.asu.edu/authz:self',
];
const dplScopes = [
  'https://api.myasuplat-dpl.asu.edu/scopes/person/read:self',
  'https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self',
  'https://api.myasuplat-dpl.asu.edu/scopes/phone/read:self',
  'https://api.myasuplat-dpl.asu.edu/scopes/acad-plan/read:self',
];
// merge environment scopes with the mandatory dplScopes
const scopeSet = new Set([
  ...(env.REACT_APP_SCOPE?.split(' ') || defaultScopes),
  ...dplScopes,
]);
// ============================================================================
const authSettings: AuthAppSettings = {
  loginUrl: env.REACT_APP_LOGIN_URL || defaultLoginUrl,
  redirectUrl: env.REACT_APP_REDIRECT_URL || defaultRedirectUrl,
  clientId: env.REACT_APP_CLIENT_ID || defaultClientId,
  scopes: Array.from(scopeSet),
  notAuthorizedUrl: appRouterUrl.NOT_AUTHORIZED,
};
// ============================================================================

export { authSettings };
