import { ElementRef, FC, useEffect, useRef } from 'react';
import styles from './index.module.scss';

const VAR_WITH_START = '--chip-width-start';
const VAR_WITH_END = '--chip-width-end';

type Props = {
  className?: string;
  text: string | React.ReactNode;
  hiddenContent: string;
  bgColor?: string;
  bgActiveColor?: string;
};

const resetSize = (chipText: HTMLSpanElement) => {
  chipText.setAttribute('data-hidden', 'true');
  chipText.style.setProperty(VAR_WITH_START, 'auto');
  chipText.style.setProperty(VAR_WITH_END, 'auto');
};

const setSize = (chipText: HTMLSpanElement) => {
  chipText.removeAttribute('data-hidden');

  const chipWidth = chipText.offsetWidth + 1;
  chipText.style.setProperty(VAR_WITH_START, '0px');
  chipText.style.setProperty(VAR_WITH_END, `${chipWidth}px`);
};

const computeSize = (chipText: HTMLSpanElement) => {
  resetSize(chipText);
  setSize(chipText);
};

// This function covers those case when one of the ParentNode is `display: none`
const computeSizeIfNecessary = (
  chipText: HTMLSpanElement,
  minLength: number,
) => {
  const chipWidth = chipText.style.getPropertyValue(VAR_WITH_END);

  if (Number.parseInt(chipWidth) < minLength) {
    computeSize(chipText);
  }
};

const defaultBGColor = '#e8e8e8';

const CollapsibleChip: FC<Props> = ({
  className = '',
  text,
  hiddenContent,
  bgColor = defaultBGColor,
  bgActiveColor = defaultBGColor,
}) => {
  const refText = useRef<ElementRef<'span'>>(null);

  useEffect(() => {
    if (refText.current) {
      computeSize(refText.current);
    }
  }, []);

  return (
    <div
      className={`${styles.container} ${className}
       flex items-center px-2 py-3 text-sm leading-8`}
      style={{
        ['--bg-color' as string]: bgColor,
        ['--bg-active-color' as string]: bgActiveColor,
      }}
      data-chip
      onMouseOver={() => {
        computeSizeIfNecessary(refText.current!, hiddenContent.length);
      }}
    >
      <strong>{text}</strong>
      <span
        ref={refText}
        className={styles['chip-hidden-content']}
        data-hidden="true"
      >
        &nbsp;{hiddenContent}
      </span>
    </div>
  );
};

export { CollapsibleChip };
