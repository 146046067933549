import { FC, useEffect, useRef } from 'react';

import styles from './index.module.scss';

type Props = {
  className?: string;
  text: string | React.ReactNode;
  hiddenContent: string;
  bgColor?: string;
  bgActiveColor?: string;
  onClick?: () => void;
};

const setVisibleTextSize = (
  visibleText: HTMLElement,
  container: HTMLElement,
) => {
  container.style.setProperty(
    '--chip-visible-text-width',
    `${visibleText.offsetWidth}px`,
  );
};

const defaultBGColor = '#e8e8e8';

const OverflowChip: FC<Props> = ({
  className = '',
  text,
  hiddenContent,
  bgColor = defaultBGColor,
  bgActiveColor = defaultBGColor,
  onClick = undefined,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refText = useRef<HTMLElement>(null);

  useEffect(() => {
    if (refContainer.current && refText.current) {
      setVisibleTextSize(refText.current, refContainer.current);
    }
  }, []);

  return (
    <div
      ref={refContainer}
      className={`${styles.container} ${className} relative rounded-lg`}
      data-chip
      style={{
        ['--bg-color' as string]: bgColor,
        ['--bg-active-color' as string]: bgActiveColor,
      }}
      tabIndex={onClick ? 0 : undefined}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      onMouseLeave={() => {
        refContainer!.current?.removeAttribute('data-hover');
      }}
    >
      <span
        className={`${styles.content}
                    flex h-4 w-fit items-center
                    rounded-lg
                    px-2 py-3 text-sm leading-8`}
      >
        <strong
          className={styles['chip-visible-content']}
          ref={refText}
          onMouseOver={() => {
            refContainer!.current?.setAttribute('data-hover', 'true');
          }}
        >
          {text}
        </strong>
        <span className={styles['chip-hidden-content']} data-hidden="true">
          &nbsp;{hiddenContent}
        </span>
      </span>
    </div>
  );
};

export { OverflowChip };
