import { FC, ReactNode } from 'react';

import styles from './index.module.scss';

type Props = {
  children?: ReactNode;
};

const DummyPlaceholder: FC<Props> = ({ children }) => {
  return (
    <section className={styles.container}>
      <div className="content">
        <h2>{children}</h2>
        <h3>Under Construction</h3>
      </div>
    </section>
  );
};

export { DummyPlaceholder };
