import { FC, ReactNode } from 'react';
import { Button, Modal, WifiSlashSVG } from 'core/components';

const FaIcon = ({ className }: { className: string }) => (
  <i className={className} />
);
const modalIcons = {
  delete: (className?: string) => (
    <FaIcon className={`fa-solid fa-trash ${className}`} />
  ),
  reset: (className?: string) => (
    <FaIcon className={`fa-solid fa-clock-rotate-left ${className}`} />
  ),
  info: (className?: string) => (
    <FaIcon className={`fa-solid fa-question ${className}`} />
  ),
  warning: (className?: string) => (
    <FaIcon className={`fa-solid fa-exclamation ${className}`} />
  ),
  fail: (className?: string) => (
    <FaIcon className={`fa-regular fa-face-frown ${className}`} />
  ),
  offline: (className?: string) => <WifiSlashSVG className={`${className}`} />,
};

type AlertType = keyof typeof modalIcons;

type EventProps =
  | {
      type?: 'OK_CANCEL';
      onConfirm: () => void;
      onCancel: () => void;
    }
  | {
      type?: 'OK';
      onConfirm: () => void;
      onCancel?: undefined;
    }
  | {
      type?: 'CANCEL';
      onConfirm?: undefined;
      onCancel: () => void;
    };

type Props = {
  showModal: boolean;
  icon?: keyof typeof modalIcons;
  title?: string;
  body: ReactNode;
  btnConfirmText?: string;
  btnCancelText?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  onClose?: () => void;
} & EventProps;

const AlertModal: FC<Props> = ({
  showModal,
  type = 'OK',
  icon,
  title,
  body,
  btnConfirmText = 'Ok',
  btnCancelText = 'Cancel',
  width,
  height,
  onConfirm,
  onCancel,
  onClose,
}) => {
  return (
    <Modal
      showModal={showModal}
      showCloseXButton={false}
      width={width}
      height={height}
      closeOnEscape={false}
      onClose={onClose}
    >
      <header className="mb-2 flex flex-col items-center">
        {icon && (
          <div className="bg-gray-2 mb-6 grid aspect-square place-content-center rounded-full p-8">
            <span>{modalIcons[icon]('h-12 aspect-square')}</span>
          </div>
        )}
        {title && <h2 className="w-fit text-2xl">{title}</h2>}
      </header>

      {body && (
        <section className="m-inline-auto mb-5 mt-4 justify-center text-base leading-6 tracking-normal text-gray-600">
          {body}
        </section>
      )}

      <footer
        data-type={type}
        className={`flex justify-${
          ['OK', 'CANCEL'].includes(type) ? 'center' : 'between'
        } self-end`}
      >
        {['OK_CANCEL', 'CANCEL'].includes(type) && (
          <Button onClick={onCancel}>{btnCancelText}</Button>
        )}

        {['OK_CANCEL', 'OK'].includes(type) && (
          <Button buttonType="primary" onClick={onConfirm}>
            {btnConfirmText}
          </Button>
        )}
      </footer>
    </Modal>
  );
};

export { AlertModal, type AlertType };
