// @ts-check

/**
 * Check if a function is a Async.
 * @param {any} func
 */
function isAsyncFunction(func) {
  const isAsync =
    typeof func === 'function' && func.constructor.name === 'AsyncFunction';

  return isAsync;
}

export { isAsyncFunction };
