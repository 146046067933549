import { FC, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useSetCssHeightProperty } from 'core/hooks/use-set-css-property';
import styles from './index.module.scss';

type Props = {
  className?: string;
  contentCentered?: boolean;
  sticky?: boolean;
  children: ReactNode;
};

const BannerPortalContainer: FC<Props> = ({
  className = '',
  sticky = true,
  contentCentered = true,
  children,
}) => {
  const refNode = useRef<HTMLDivElement>(null);
  const stickyClass = sticky ? 'sticky top-0 z-100 ' : '';
  const justifyClass = contentCentered ? 'place-content-center' : '';
  useSetCssHeightProperty({
    refNode,
    cssPropName: '--top-banner-height',
  });

  const element = document.querySelector('#body-top-section');

  if (!element) return null;

  return createPortal(
    <div
      role="alert"
      ref={refNode}
      className={`${styles.container} ${className} border-gray-3`}
    >
      <div
        className={`${styles.body} container py-3 ${stickyClass} ${justifyClass} `}
      >
        {children}
      </div>
    </div>,
    element,
  );
};

export { BannerPortalContainer };
