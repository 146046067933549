/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

type Props = {
  className?: string;
  title: string;
  items: string[];
};

const BlockList: FC<Props> = ({ className, title, items }) => {
  return (
    <article className={className}>
      <h4>{title}</h4>
      {items?.length > 0 ? (
        <ul>{items?.map((item, index) => <li key={index}>{item}</li>)}</ul>
      ) : (
        <p>None</p>
      )}
    </article>
  );
};

export { BlockList };
