const createRange = (from: number, to: number, stop: number) => {
  let i = from;
  const range = [];

  while (i < to) {
    if (i > 0 && i <= stop) range.push(i);
    i += 1;
  }

  return range;
};

const iff = <T extends {}>(condition: boolean, then: T, otherwise: T) =>
  condition ? then : otherwise;

const slicePageRows = <T extends {}>(
  data: T[],
  pageIndex: number,
  maxRowPage: number,
) => {
  const fromRecord = pageIndex * maxRowPage;
  const toRecord = fromRecord + maxRowPage;
  const rows = data.slice(fromRecord, toRecord);

  return rows;
};

export { createRange, iff, slicePageRows };
