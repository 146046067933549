import { RoleName } from 'core/constants/app-constants';

function canRoleImpersonate(currentRole: UserProfileRole | null) {
  if (currentRole === RoleName.PLATFORM || currentRole === RoleName.ADMIN) {
    return true;
  }

  return false;
}

/**
 * For users with multiple roles pick the one with more privileges
 */
function pickPrimaryRole(userRoles: string[]) {
  if (!userRoles || userRoles.length === 0) return null;

  if (userRoles.includes(RoleName.PLATFORM)) {
    return RoleName.PLATFORM;
  } else if (userRoles.includes(RoleName.ADMIN)) {
    return RoleName.ADMIN;
  } else if (userRoles.includes(RoleName.ADVISOR)) {
    return RoleName.ADVISOR;
  } else if (userRoles.includes(RoleName.COACH)) {
    return RoleName.COACH;
  }
  return userRoles[0] as UserProfileRole;
}

function pickEmplid(appState: unknown, useSearchProfile = false): string {
  const state = appState as AppState;
  const { viewAs, emplid, search } = state.userProfile;
  let currentEmplid = emplid;

  if (useSearchProfile) {
    currentEmplid = search.selectedUser?.profile.emplid || '';
  } else if (viewAs.active) {
    currentEmplid = viewAs.emplid;
  }

  return currentEmplid || '';
}

function processPermission(scopes: string[]) {
  const hasPermission = (key: string) =>
    scopes.some((value) => new RegExp(key).test(value));

  const permissions = {
    canReadPlan: hasPermission('/plan/read:self'),
    canReadAllPlan: hasPermission('/plan/read:all'),
    canEditPlan: hasPermission('/plan/write:self'),
    canEditAllPlan: hasPermission('/plan/write:all'),
  };

  return permissions;
}

const pickCurrentRoleSelector = (state: AppState) =>
  state.userProfile.viewAs.currentRole || state.userProfile.currentRole;

export {
  pickPrimaryRole,
  pickEmplid,
  pickCurrentRoleSelector,
  processPermission,
  canRoleImpersonate,
};
