import {
  createContext,
  FC,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { SpinnerLoader } from 'core/components';
import { SpinnerLoaderProps } from 'core/components/Loaders/SpinnerLoader';

type Settings = Partial<SpinnerLoaderProps>;

type Props = {
  children: React.ReactElement;
};

type Content = React.ReactNode;

type LoaderContextState = {
  loader: {
    showMessage: (content: Content, settings?: Settings) => void;
    hideMessage: () => void;
  };
};

const LoaderContext = createContext<LoaderContextState>({
  loader: {
    showMessage: () => undefined,
    hideMessage: () => undefined,
  },
});

const LoaderProvider: FC<Props> = ({ children }) => {
  const [loaderContent, setLoaderContent] = useState<Content>('');
  const refSettings = useRef<Settings>();

  const contextValue: LoaderContextState = useMemo(
    () => ({
      loader: {
        showMessage: (content, settings) => {
          setLoaderContent(content);
          refSettings.current = settings;
        },
        hideMessage: () => {
          setLoaderContent(null);
        },
      },
    }),
    [],
  );

  return (
    <LoaderContext.Provider value={contextValue}>
      <div className="min-h-inherit relative h-full">
        {children}

        {loaderContent && (
          <SpinnerLoader
            spinnerMessage={loaderContent}
            fullSize
            {...refSettings.current}
          />
        )}
      </div>
    </LoaderContext.Provider>
  );
};

const useLoaderContext = () => {
  return useContext(LoaderContext);
};

export { LoaderProvider, useLoaderContext };
