/// <reference path="./index.d.ts" />

import { WorkerManager } from '../WorkerManager';

type Settings = {
  logMessage: boolean;
};

class HttpQueueWorkerManager extends WorkerManager<HttpQueueWorker.PostMessageData> {
  constructor({ logMessage = false } = {} as Settings) {
    super({
      worker: new Worker(new URL('./worker.ts', import.meta.url), {
        name: 'HttpQueueWorker',
      }),
    });

    if (logMessage) {
      this.postMessage({
        action: 'log-message',
      });
    }
  }

  enqueueRequest(request: HttpQueueWorker.Request) {
    return this.postMessage({
      action: 'enqueue-request',
      payload: request,
    });
  }
}

type OnMessageData<
  TRequestPayload = unknown,
  TResponse = unknown,
> = HttpQueueWorker.OnMessageData<TRequestPayload, TResponse>;

export { HttpQueueWorkerManager, type OnMessageData };
