import { ElementRef, FC, useEffect, useRef } from 'react';

import { useMakeId } from 'core/hooks/use-make-id';
import styles from './index.module.scss';

type Props = {
  shape: 'rounded' | '';
  describedby?: string;
  inputType?: 'checkbox' | 'radio';
  width?: string;
  value: boolean;
  onChange: () => void;
};

const Switch: FC<Props> = ({
  shape,
  inputType = 'checkbox',
  describedby,
  width = '60px',
  value,
  onChange,
}) => {
  const id = useMakeId('switch-');
  const shapeClass = shape === 'rounded' ? styles.round : '';
  const refSlider = useRef<ElementRef<'span'>>(null);

  useEffect(() => {
    if (refSlider.current) {
      refSlider.current.style.setProperty(
        '--slider-width',
        getComputedStyle(refSlider.current, '::before').width,
      );
    }
  }, []);

  return (
    <label
      className={`${styles.switch} focus-container h-fit ` + shapeClass}
      htmlFor={id}
      aria-describedby={describedby}
      style={{
        ['--switch-width' as string]: width,
      }}
    >
      <input
        id={id}
        type={inputType}
        onChange={onChange}
        checked={value}
        value={String(value)}
        role="switch"
        aria-checked={value}
      />
      <span ref={refSlider} className={styles.slider}></span>
    </label>
  );
};

export { Switch };
