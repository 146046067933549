import { useEffect } from 'react';

const enableLogs = false;
const warn = enableLogs
  ? function (message?: any, ...optionalParams: any[]) {
      console.warn(message, optionalParams);
    }
  : () => undefined;

function useWebpackError() {
  /**
   * New version of webpack trigger an error in `dev` mode
   * This hide the React div overlay.
   * TODO: verify if new webpack version fixed the error
   */
  useEffect(() => {
    let resizeObserverErrDiv: HTMLElement | null,
      resizeObserverErr: HTMLElement | null;

    function onWindowError(e: ErrorEvent) {
      if (
        e.message ===
          'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'Script error.' // This specific error message is for Safari only
      ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();

        warn('dnd-kit', e);

        resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    }
    window.addEventListener('error', onWindowError);

    return () => {
      resizeObserverErrDiv && resizeObserverErrDiv.removeAttribute('style');
      resizeObserverErr && resizeObserverErr.removeAttribute('style');
      window.removeEventListener('error', onWindowError);
    };
  }, []);
}

export { useWebpackError };
