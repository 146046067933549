import { FC } from 'react';

import { BannerPortalContainer } from './BannerPortalContainer';

const ReadonlyBanner: FC = () => {
  return (
    <BannerPortalContainer className="bg-info">
      <strong>You’re viewing this plan in Readonly Mode.</strong>
    </BannerPortalContainer>
  );
};

export { ReadonlyBanner };
