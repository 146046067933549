// @ts-check

import {
  recoverState,
  storeState,
} from '../sso/services/auth-local-state-service';
import { formatLog } from '../utils/logger-utils';

export const actionTypes = /** @type {const} */ ({
  STORE_AUTH_LOCAL_STORAGE: 'STORE_AUTH_LOCAL_STORAGE',
  RECOVER_AUTH_LOCAL_STORAGE: 'RECOVER_AUTH_LOCAL_STORAGE',
  RETURN_URL: 'RETURN_URL',
  INITIALIZED: 'INITIALIZED',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_CLAIM: 'SET_CLAIM',
  SET_USER_ROLES: 'SET_USER_ROLES',
  LOGOUT: 'LOGOUT',
  ERROR: 'ERROR',
});

/**
 * @type {AuthState}
 */
export const initialAuthState = {
  isAuthenticated: false,
  error: undefined,
  user: '',
  isLoading: true,
  errorBlocker: false,
  userRoles: {
    loading: false,
    completed: false,
    roles: [],
  },
  ssoName: '',
  claim: {},
};

/**
 * @param {AuthState} state
 * @param {AuthAction} action
 * @return {AuthState}
 */
export const reducer = (state, action) => {
  console.log(...formatLog({ message: 'Reducer' }), { action, state });

  switch (action.type) {
    case actionTypes.STORE_AUTH_LOCAL_STORAGE:
      storeState(state);

      return state;
    case actionTypes.RECOVER_AUTH_LOCAL_STORAGE:
      const localState = recoverState();

      return {
        ...state,
        ...localState,
      };
    case actionTypes.SET_AUTHENTICATED:
      const isAuthenticated = true;

      return {
        ...state,
        isAuthenticated,
      };
    case actionTypes.INITIALIZED:
      return {
        ...state,
        user: action.user,
        ssoName: action.ssoName,
        returnUrl: action.returnUrl,
        error: undefined,
        isLoading: false,
      };
    case actionTypes.SET_CLAIM: {
      return {
        ...state,
        claim: action.claim || {},
      };
    }
    case actionTypes.SET_USER_ROLES:
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          ...action.userRoles,
        },
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
        error: undefined,
        errorBlocker: false,
      };
    case actionTypes.ERROR:
      return {
        ...state,
        errorBlocker: !!action.errorBlocker,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
