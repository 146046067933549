function isNumber(value?: unknown, checkGTZero = false) {
  const parsedValue = parseInt(value as string, 10);
  const isValid = !isNaN(parsedValue);

  if (isValid && checkGTZero) {
    return parsedValue > 0;
  }

  return isValid;
}

function parseNumberDigits(value: number, fractionDigits = 2) {
  // nn.nnn > 5chars = KO
  // nn.nn <= 5chars = OK
  if (value.toString().length > 5) {
    return parseFloat(value.toFixed(fractionDigits));
  }
  return value;
}

export { isNumber, parseNumberDigits };
