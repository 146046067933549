import {
  createContext,
  FC,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { AlertModal } from 'core/components';
import { type AlertType } from 'core/components/AlertModal';

type Props = {
  children: React.ReactElement;
};

type Settings = Partial<{
  onConfirm: () => void;
  onClose: () => void;
  icon?: AlertType;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}>;

type Content = React.ReactNode;

type TriggerFunc = (content: Content, settings?: Settings) => void;

type AlertContextState = {
  dialog: {
    showWarning: TriggerFunc;
    showInfo: TriggerFunc;
    showFail: TriggerFunc;
  };
};

const AlertContext = createContext<AlertContextState>({
  dialog: {
    showWarning: window.alert,
    showInfo: window.alert,
    showFail: window.alert,
  },
});

const AlertProvider: FC<Props> = ({ children }) => {
  const [alertType, seAlertType] = useState<AlertType>();
  const [alertContent, setAlertContent] = useState<Content>('');
  const [alertOpen, setAlertOpen] = useState(false);
  const refSettings = useRef<Settings>();

  const gotIt = () => {
    setAlertOpen(false);
    refSettings.current?.onConfirm?.();
  };

  const setBaseProps = (
    content: Content,
    type: AlertType,
    settings?: Settings,
  ) => {
    seAlertType(type);
    setAlertContent(content);
    setAlertOpen(true);
    refSettings.current = settings;
  };

  const contextValue: AlertContextState = useMemo(
    () => ({
      dialog: {
        showInfo: (content, settings) => {
          setBaseProps(content, 'info', settings);
        },
        showWarning: (content, settings) => {
          setBaseProps(content, 'warning', settings);
        },
        showFail: (content, settings) => {
          setBaseProps(content, 'fail', settings);
        },
      },
    }),
    [],
  );

  return (
    <AlertContext.Provider value={contextValue}>
      {children}

      {createPortal(
        <AlertModal
          showModal={alertOpen}
          icon={alertType}
          body={alertContent}
          onConfirm={gotIt}
          width={refSettings.current?.width}
          height={refSettings.current?.height}
        />,

        document.body,
      )}
    </AlertContext.Provider>
  );
};

const useAlertContext = () => useContext(AlertContext);

export { AlertProvider, useAlertContext };
