import {
  Children,
  cloneElement,
  ElementRef,
  FC,
  ReactElement,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import styles from './index.module.scss';

type ReactMouseEvent = React.MouseEvent<HTMLElement, MouseEvent>;
type ReactKeyboardEvent = React.KeyboardEvent<HTMLElement>;
type OpenMenuEvent = { e: ReactMouseEvent };

//#region "MenuItem"
type MenuItemProps = {
  children: React.ReactNode;
  /**
   * When `false` (default) the MenuList allow navigation between `<li />` items
   * Whe `true` the MenuList expects that the `tabindex` is set externally.
   * @example
   * <MenuItem><a>My link</a></MenuItem>
   * @example
   * <MenuItem><button>My link</button></MenuItem>
   * @example
   * <MenuItem><div tabIndex={0}>My link</div></MenuItem>
   */
  customTabIndex?: boolean;
  disabled?: boolean;
} & React.FormHTMLAttributes<HTMLLIElement>;

const findSibling = (
  target: HTMLElement,
  sideSibling: 'previousSibling' | 'nextSibling',
) => {
  let sibling = target;
  let isDisabled;

  do {
    sibling = sibling[sideSibling] as HTMLElement;
    isDisabled = sibling?.getAttribute('data-disabled');

    if (isDisabled === 'false' || !isDisabled) {
      break;
    }
  } while (sibling);

  return sibling;
};

const MenuItem = ({
  children,
  customTabIndex = false,
  disabled = false,
  onClick,
  ...props
}: MenuItemProps) => {
  const selectMenuItem = (e: ReactKeyboardEvent) => {
    if (!['ArrowUp', 'ArrowDown'].includes(e.key)) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const target = e.currentTarget as HTMLElement;
    let sibling = null;

    if (e.key === 'ArrowUp') {
      sibling = findSibling(target, 'previousSibling');
    } else if (e.key === 'ArrowDown') {
      sibling = findSibling(target, 'nextSibling');
    }

    if (sibling) {
      const element = sibling.hasAttribute('tabindex')
        ? sibling
        : sibling.querySelector<HTMLElement>('a,[tabindex="0"]');

      if (element?.focus) {
        element.focus();
      }
    }
  };

  return (
    <li
      {...props}
      role="menuitem"
      tabIndex={customTabIndex || disabled ? undefined : 0}
      data-disabled={disabled}
      onKeyDown={disabled ? undefined : selectMenuItem}
      onClick={disabled ? undefined : onClick}
    >
      {children}
    </li>
  );
};
//#endregion

//#region "MenuList"

type MenuChild = ReactElement;
type MenuTriggerProps = React.FormHTMLAttributes<HTMLElement> & {
  ref: React.RefObject<any>;
};
type Props = {
  className?: string;
  children: MenuChild | MenuChild[];
  disabled?: boolean;
  menuIsOpen?: boolean;
  /**
   * Pause the action of closing for the specified value
   */
  closingDelayTimeout?: number;
  /**
   * set which direction the MenuList shows up
   */
  direction?: 'right' | 'left';
  /**
   * Any valid JSX element to which attach the events to show the MenuList
   *  @example
   * (props) =>(<Button {...props}>Click to open the menu</Button>)
   */
  triggerComponent: (props: MenuTriggerProps) => ReactElement;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

let menuEnterTimer: NodeJS.Timer;
let menuCloseTimer: NodeJS.Timer;

const MenuList: FC<Props> = ({
  triggerComponent,
  children,
  className,
  disabled = false,
  menuIsOpen = false,
  closingDelayTimeout = 300,
  direction = 'left',
  onClick,
}) => {
  const [isOpen, setOpen] = useState(menuIsOpen);
  const [pointerEventActive, setPointerEventActive] = useState(true);
  const menuRef = useRef<ElementRef<'ul'>>(null);
  const menuTriggerRef = useRef<HTMLElement>(null);
  const id = useId();

  const showMenu = ({ e }: OpenMenuEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const menu = menuRef.current!;

    if (direction === 'right') {
      menu.style.left = 'unset';
      menu.style.right = '0px';
    } else {
      menu.style.left = '0px';
      menu.style.right = 'unset';
    }

    menu.style.visibility = '';
    menu.style.display = '';

    setOpen(true);
  };

  const hideMenu = () => {
    setOpen(false);
    clearTimeout(menuEnterTimer);

    const menu = menuRef.current;
    if (isOpen && menu) {
      menu?.setAttribute('data-animate-hidden', 'true');

      menuEnterTimer = setTimeout(() => {
        menu.setAttribute('data-animate-hidden', 'false');
      }, closingDelayTimeout);
    }
  };

  const selectFirstMenuItem = (e: ReactKeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.stopPropagation();
      e.preventDefault();
      const menu = menuRef.current;
      const target = menu?.querySelector<HTMLElement>('a,[tabindex="0"]');
      target?.focus();
    }
  };

  const leaveAfterLastMenuItem = (e: ReactKeyboardEvent) => {
    const target = e.target as HTMLElement;
    const isLastChild = menuRef.current!.lastChild!.contains(target);
    if (e.key === 'Tab' && isLastChild) {
      hideMenu();
    }
  };

  const toggleMenu = ({ e }: OpenMenuEvent) => {
    isOpen ? hideMenu() : showMenu({ e });
  };

  useEffect(() => {
    if (!menuRef.current || !menuTriggerRef.current) return;

    const closeMenu = () => setOpen(false);

    const events = ['click', 'scroll', 'resize'];
    events.forEach((name) => window.addEventListener(name, closeMenu));

    return () => {
      events.forEach((name) => window.removeEventListener(name, closeMenu));
      clearTimeout(menuEnterTimer);
    };
  }, []);

  const listItems = Children.map(children, (child, index) =>
    cloneElement(child, {
      'data-index': index,
    }),
  );

  return (
    <div
      className="relative"
      data-menu-list
      onMouseLeave={() =>
        (menuCloseTimer = setTimeout(hideMenu, closingDelayTimeout))
      }
    >
      {triggerComponent(
        disabled
          ? ({} as MenuTriggerProps)
          : {
              ref: menuTriggerRef,
              'aria-controls': id,
              'aria-expanded': isOpen,
              'aria-haspopup': 'true',
              onKeyDown: selectFirstMenuItem,
              onClick: (e) => {
                e.stopPropagation();
                e.preventDefault();
                pointerEventActive && toggleMenu({ e });
              },
              onMouseEnter: (e) => {
                setPointerEventActive(false);
                showMenu({ e });
                menuEnterTimer = setTimeout(
                  () => setPointerEventActive(true),
                  800,
                );
              },
            },
      )}
      <ul
        ref={menuRef}
        className={`${className} ${styles['list']} absolute`}
        id={id}
        role={isOpen ? 'menu' : 'presentation'}
        data-is-open={isOpen}
        tabIndex={-1}
        data-direction={direction}
        onClick={onClick}
        onKeyDown={leaveAfterLastMenuItem}
        onMouseEnter={(e) => clearTimeout(menuCloseTimer)}
      >
        {listItems}
      </ul>
    </div>
  );
};
//#endregion

export { MenuList, MenuItem };
