// @ts-check
import axios from 'axios';
import Lock from 'browser-tabs-lock';
import { decodeJwt } from 'jose';

import { formatLog } from '../../utils/logger-utils';
import { getTokenFromStorage } from '../utils/get-token-from-storage';

import {
  bufferToBase64UrlEncoded,
  createRandomString,
  encode,
  sha256,
} from '../utils/utils';
import { AuthError } from './auth-error';
import { clearState } from './auth-local-state-service';

const lock = new Lock();
class Auth0ClientService {
  settings = /** @type {Required<AuthSettings>} */ ({});

  /**
   * @param {AuthSettings} settings
   * @memberof Auth0ClientService
   */
  constructor(settings) {
    this.settings = /** @type {Required<AuthSettings>} */ (settings);
  }

  /**
   * @type {function | undefined | null}
   */
  onRedirect = null;

  /** @return {Promise<void>}  */
  async loginWithRedirect() {
    this.onRedirect?.();
    clearState();
    const url = await this.buildAuthorizeUrl();
    window.location.assign(url);
  }

  async buildAuthorizeUrl() {
    const state = encode(createRandomString());
    const codeVerifier = createRandomString();
    const codeChallengeBuffer = await sha256(codeVerifier);
    const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer);

    localStorage.setItem(this.settings.codeVerifierKey, codeVerifier);
    localStorage.setItem(this.settings.stateKey, state);
    localStorage.setItem(
      this.settings.returnToKey,
      `${window.location.pathname}${window.location.search}`,
    );

    let url = this.settings.authUrl;
    url += '?response_type=code';
    url += '&client_id=' + encodeURIComponent(this.settings.clientId);
    url += '&redirect_uri=' + encodeURIComponent(this.settings.redirectUrl);
    url += '&state=' + encodeURIComponent(state);
    url += '&code_challenge_method=S256';
    url += '&code_challenge=' + codeChallenge;
    url += '&scope=' + encodeURIComponent(this.settings.scopes.join(' '));

    console.log(`redirecting to auth server [${url}]`);

    return url;
  }

  async requestServiceAuthToken() {
    const searchParams = new URLSearchParams(window.location.search);

    if (Array.from(searchParams).length === 0) {
      throw new AuthError('There are no query params available for parsing.');
    }

    const code = searchParams.get('code') || '';

    const codeVerifier =
      localStorage.getItem(this.settings.codeVerifierKey) || '';

    localStorage.removeItem(this.settings.tokenKeyUpdateTime);
    localStorage.removeItem(this.settings.stateKey);
    localStorage.removeItem(this.settings.codeVerifierKey);

    // TODO: the ASU AuthService looks using a different encoding strategy
    // const state = searchParams.get('state');
    // const storedState = localStorage.getItem(stateKey);
    // so this match would not always work. e.g. the char `+` is replaced with ` `
    // if (state !== storedState) {
    //   throw new AuthError('State values do not match.');
    // }
    /* eslint-disable camelcase */
    const response = await axios.post(
      this.settings.tokenUrl,
      new URLSearchParams({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.settings.redirectUrl,
        client_id: this.settings.clientId,
        client_secret: this.settings.clientSecret,
        code_verifier: codeVerifier,
      }).toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    /* eslint-enable camelcase */

    const { data } = response;

    localStorage.setItem(this.settings.tokenClaimKey, JSON.stringify(data));
    localStorage.setItem(this.settings.tokenKey, data.access_token);
    localStorage.setItem(this.settings.refreshTokenKey, data.refresh_token);
    localStorage.setItem(
      this.settings.tokenKeyCreateTime,
      new Date().toLocaleString(),
    );

    // return url
    const returnTo = this.getReturnUrl();

    return {
      returnTo,
      data,
    };
  }

  getReturnUrl() {
    let returnTo = localStorage.getItem(this.settings.returnToKey);
    localStorage.removeItem(this.settings.returnToKey);

    // If user is logging in from login page, return to redirect url
    if (returnTo?.startsWith(this.settings.loginUrl)) {
      returnTo = '/';
    }

    return returnTo;
  }

  async requestRefreshToken() {
    console.log(...formatLog({ message: 'Refresh token session' }));

    try {
      const refreshToken =
        localStorage.getItem(this.settings.refreshTokenKey) || '';
      const url = this.settings.refreshTokenUrl;
      /* eslint-disable camelcase */
      const { data } = await axios.post(
        url,
        new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: this.settings.clientId,
          /* NB: don't use `encodeURIComponent(scopes.join(' '))
         or encodeURI` the response would return
         `{ access_token: 'xxx ,  scope: []}`
         for which the new access_token would not be valid  */
          scope: this.settings.scopes.join(' '),
        }).toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      /* eslint-enable camelcase */
      localStorage.setItem(this.settings.tokenKey, data.access_token);
      localStorage.setItem(
        this.settings.tokenKeyUpdateTime,
        new Date().toLocaleString(),
      );
      console.log(...formatLog({ message: 'Token got refreshed' }));
    } catch (error) {
      console.log(...formatLog({ message: 'Token refresh error: ' + error }));
    }
  }

  /** @return {Promise<AppJwtToken | undefined>}   */
  async checkSession() {
    console.log(...formatLog({ message: 'Validate token session' }));

    if (!localStorage.getItem(this.settings.tokenKey)) {
      console.log(...formatLog({ message: 'no jwt in session storage' }));
      return;
    }

    try {
      const jwt = await this.getAuthToken();
      return jwt;
    } catch (error) {
      console.log(...formatLog({ message: 'check session error: ' }), error);
    }
  }

  /**
   * 1. Check storage and verify valid
   * 2. Use refresh token
   * 3. loginWithRedirect
   * @returns {Promise<AppJwtToken | undefined>}
   */
  async getAuthToken() {
    const token = await getTokenFromStorage(this.settings.tokenKey);
    if (token) return token;

    /**
     * @param {() => Promise<boolean>} cb
     * @param {number} [maxNumberOfRetries=3]
     */
    const retryPromise = async (cb, maxNumberOfRetries = 3) => {
      for (let i = 0; i < maxNumberOfRetries; i++) {
        console.log(`In retryPromise: i=${i}`);
        if (await cb()) {
          return true;
        }
      }

      return false;
    };

    const LOCK_KEY = 'get_token_silently';

    if (await retryPromise(() => lock.acquireLock(LOCK_KEY), 10)) {
      try {
        console.log('checking for tokens again');
        const token = await getTokenFromStorage(this.settings.tokenKey);
        if (token) return token;

        console.log('no valid tokens - redirecting to login');
        this.loginWithRedirect();
      } finally {
        await lock.releaseLock(LOCK_KEY);
      }
    }
  }

  logout() {
    localStorage.removeItem(this.settings.tokenKey);
    localStorage.removeItem(this.settings.refreshTokenKey);
  }

  async getUser() {
    const token = localStorage.getItem(this.settings.tokenKey);

    if (!token) return '';

    try {
      const payload = decodeJwt(token);

      return String(payload?.email || payload?.sub);
    } catch (error) {
      console.error(error);
    }
  }

  async getTokenClaim() {
    const rawClaim = localStorage.getItem(this.settings.tokenClaimKey);

    if (!rawClaim) return null;

    try {
      const claim = JSON.parse(rawClaim);
      return claim;
    } catch (error) {
      console.error(error);
    }
  }
}

export { Auth0ClientService };
