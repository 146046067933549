import { FC, useState } from 'react';

import { CloseSVG, SearchSVG } from 'core/components/Icons';
import styles from './index.module.scss';

type Props = {
  id?: string;
  className?: string;
  userName: string;
};

const searchInput = () =>
  document.querySelector<HTMLInputElement>('#search-input');

const TopNavbar: FC<Props> = ({ id, className, userName }) => {
  const [searchToggle, setSearchToggle] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  return (
    <nav
      id={id}
      className={`${styles.container} ${className}`}
      aria-label="ASU"
      data-search-open={searchToggle}
    >
      <div className="links-container">
        <a
          className="nav-link sr-only-focusable sr-only"
          href={`https://accessibility.asu.edu/report?a11yref=${window.location.href}`}
        >
          Report an accessibility problem
        </a>
        <a
          className="nav-link sr-only-focusable sr-only"
          href="#skip-to-content"
        >
          Skip to main content
        </a>

        <a className="nav-link" href="https://asu.edu">
          ASU Home
        </a>
        <a className="nav-link" href="https://my.asu.edu">
          My ASU
        </a>
        <a
          className="nav-link"
          href="https://www.asu.edu/academics/colleges-schools"
        >
          Colleges and Schools
        </a>
        <a
          className="sign-out nav-link"
          href="https://weblogin.asu.edu/cas/logout"
        >
          <strong className="name me-2" id="top-bar-user-name">
            {userName}
          </strong>
          (Sign Out)
        </a>
      </div>
      <form
        className={`${styles['form-search']} flex`}
        action="https://search.asu.edu/search"
        method="get"
        name="gs"
      >
        <button
          type="button"
          aria-label="Search asu.edu"
          className="search-button"
          disabled={searchToggle}
          onClick={() => {
            setSearchToggle(true);
            setTimeout(() => {
              searchInput()?.focus();
            }, 10);
          }}
        >
          <SearchSVG />
        </button>

        <input
          id="search-input"
          className="form-control search-input"
          type="search"
          name="q"
          aria-labelledby="header-top-search"
          placeholder="Search asu.edu"
          required
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
        />

        <button
          type="button"
          aria-label="Search asu.edu"
          className="search-close"
          onClick={() => {
            setSearchToggle(false);
            setSearchValue('');
          }}
        >
          <CloseSVG />
        </button>

        <input name="url_host" type="hidden" value="" />
        <input name="site" type="hidden" value="default_collection" />
        <input name="sort" type="hidden" value="date:D:L:d1" />
        <input name="output" type="hidden" value="xml_no_dtd" />
        <input name="ie" type="hidden" value="UTF-8" />
        <input name="oe" type="hidden" value="UTF-8" />
        <input name="client" type="hidden" value="asu_frontend" />
        <input name="proxystylesheet" type="hidden" value="asu_frontend" />
      </form>
    </nav>
  );
};

export { TopNavbar };
