import { HttpCode } from 'core/constants/app-constants';
import { formatLog } from 'core/utils/logger-utils';
import { getPlan } from './plan-service';

type PollerParams = {
  planId: string;
  emplid: string;
  onSuccess: (data: {
    status: number;
    plan: API.PlanData.Plan;
    setup: API.PlanData.ConfigPayload;
  }) => void;
  onError: (error: any) => void;
  onCompleted?: () => void;
};

let timerId: NodeJS.Timer;

function pollerLog(action: string, message?: string) {
  return formatLog({
    scope: 'Plan Poller',
    action,
    message,
  });
}

const stopPlanPoller = () => {
  clearTimeout(timerId);
};

async function startPlanPoller({
  planId,
  emplid,
  onSuccess = (_data) => {},
  onError = (_error) => {},
  onCompleted = () => {},
}: PollerParams): Promise<void> {
  const TIME_OUT = 10_000;

  console.log(...pollerLog('Start'));

  return new Promise((resolve, reject) => {
    async function runPlanPoller() {
      try {
        console.log(...pollerLog('Pending', 'Check audit plan'));

        const res = await getPlan(emplid, planId);
        const { status, plan, setup } = res;

        if (status === HttpCode.SUCCESS_CODE) {
          console.log(...pollerLog('Completed', 'Plan was found'));
          stopPlanPoller();
          onSuccess({
            status,
            plan,
            setup,
          });
          resolve();
          onCompleted();
        } else if (status === HttpCode.IN_PROGRESS_CODE) {
          console.log(...pollerLog('Pending', `Audit still in progress`));
          timerId = setTimeout(() => runPlanPoller(), TIME_OUT);
        } else {
          console.log(...pollerLog('Stop', `Status code "${status}" received`));
          stopPlanPoller();
          reject({
            status,
          });
        }
      } catch (error) {
        console.log(...pollerLog('Error', (error as Error).message));
        stopPlanPoller();
        onError(error);
        reject(error);
      }
    }

    runPlanPoller();
  });
}

export { startPlanPoller, stopPlanPoller };
