import React, { forwardRef } from 'react';

import { CloseSVG } from '../Icons';
import styles from './index.module.scss';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  clearButtonAriaLabel: string;
  onClear: () => void;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ value, clearButtonAriaLabel, onClear, ...props }, ref) => {
    const clearButtonVisible = !!value;
    return (
      <div className={styles.container}>
        <input ref={ref} value={value} {...props} />
        <button
          className="text-gray-500"
          onClick={onClear}
          aria-label={clearButtonAriaLabel}
          data-visible={clearButtonVisible}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClear();
            }
          }}
        >
          <CloseSVG tabIndex={-1} />
        </button>
      </div>
    );
  },
);

export { Input };
