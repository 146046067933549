import { AMPER_SAND } from 'core/constants/app-constants';
import { parseAsuTerm } from 'core/utils/date-utils';

function sumPlanCredits(
  studentProgress: API.PlanData.StudentProgress[],
  degreePlan: API.PlanData.Term[],
) {
  const completed =
    studentProgress.reduce((acc, cur) => acc + cur.completedCreditHour, 0) +
    degreePlan.reduce(
      (acc, cur) => acc + (cur._uiMetaData?.completedCreditHour || 0),
      0,
    );

  const inProgress = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.inProgressCreditHour!,
    0,
  );

  const registered = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.registeredCreditHour!,
    0,
  );

  const planned = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.plannedCreditHour!,
    0,
  );

  const remaining = degreePlan.reduce(
    (acc, cur) => acc + cur._uiMetaData?.remainingCreditHour!,
    0,
  );

  const total = completed + inProgress + registered + planned + remaining;

  return {
    completed,
    inProgress,
    registered,
    planned,
    remaining,
    total,
  };
}

function processSummaryData(plan: API.PlanData.Plan | null) {
  if (!plan) return;

  const { studentProgress, degreePlan, summary } = plan;
  const { cumulativeGpa: GPA, targetGraduationTerm } = summary;

  const cumulativeGpa = !GPA || GPA === 'N/A' ? AMPER_SAND : GPA;
  const targetGraduation =
    (Number.isNaN(+targetGraduationTerm)
      ? targetGraduationTerm
      : parseAsuTerm(targetGraduationTerm).sessionYear) || AMPER_SAND;

  plan.summary._uiMetaData = {
    cumulativeGpa,
    targetGraduation,
    creditHours: sumPlanCredits(studentProgress, degreePlan),
  };
  // clean potential undefined values to avoid error when there summed
  plan.summary.requirementSummaries.forEach((req) => {
    req.earnedHours ||= 0;
    req.inProgressHours ||= 0;
    req.requiredHours ||= 0;
    req.remainingHours ||= 0;
    req.registeredHours ||= 0;
  });
}

export { processSummaryData };
