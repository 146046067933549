import { FC } from 'react';
import { useAppSelector } from 'core/store';
import asuFooterLogo from 'img/asu-logo/asu-university-horiz-white.png';
import no1InnovationLockup from 'img/no-1-innovation-lockup.png';

import {
  FacebookSVG,
  InstagramSVG,
  LinkedinSVG,
  TwitterSVG,
  YoutubeSVG,
} from '../Icons';
import './index.scss';

type NavLinkProps = {
  className?: string;
  links: { content: string | React.ReactNode; href: string }[];
  ariaLabel: string;
};

const navLinks = ({ className = '', links, ariaLabel }: NavLinkProps) => {
  return (
    <nav
      className={`${className} nav flex flex-wrap gap-6 py-4`}
      aria-label={ariaLabel}
    >
      {links.map((link) => (
        <a
          key={link.href}
          className="nav-link text-gray-700 no-underline"
          href={link.href}
        >
          {link.content}
        </a>
      ))}
    </nav>
  );
};

const SocialMedia = () => {
  const links = [
    {
      content: <FacebookSVG />,
      href: 'https://www.facebook.com/arizonastateuniversity',
    },
    {
      content: <TwitterSVG />,
      href: 'https://twitter.com/asu',
    },
    {
      content: <InstagramSVG />,
      href: 'https://www.instagram.com/arizonastateuniversity',
    },
    {
      content: <YoutubeSVG />,
      href: 'https://www.youtube.com/asu',
    },
    {
      content: <LinkedinSVG />,
      href: 'https://www.linkedin.com/company/arizona-state-university',
    },
  ];

  return navLinks({
    className: 'social-media items-center gap-8',
    links,
    ariaLabel: 'Social Media',
  });
};

const Contacts = () => {
  const links = [
    {
      content: 'Maps and Locations',
      href: 'https://www.asu.edu/about/locations-maps',
    },
    {
      content: 'Jobs',
      href: 'https://www.asu.edu/asujobs',
    },
    {
      content: 'Directory',
      href: 'https://isearch.asu.edu/',
    },
    {
      content: 'Contact ASU',
      href: 'https://www.asu.edu/about/contact',
    },
    {
      content: 'My ASU',
      href: 'https://my.asu.edu/',
    },
  ];

  return navLinks({ links, ariaLabel: 'University Services' });
};

const QuickLinks = () => {
  const links = [
    {
      content: 'Copyright and Trademark',
      href: 'https://www.asu.edu/about/copyright-trademark',
    },
    {
      content: 'Accessibility',
      href: `https://accessibility.asu.edu/report?a11yref=${window.location.href}`,
    },
    { content: 'Privacy', href: 'https://www.asu.edu/about/privacy' },
    {
      content: 'Terms of Use',
      href: 'https://www.asu.edu/about/terms-of-use',
    },
    {
      content: 'Emergency',
      href: 'https://www.asu.edu/emergency/',
    },
    {
      content: 'COVID-19 Information',
      href: 'https://eoss.asu.edu/health/announcements/coronavirus',
    },
  ];

  return navLinks({ links, ariaLabel: 'University Legal and Compliance' });
};

const Footer: FC = () => {
  const showFooter = useAppSelector((state) => state.shared.showFooter);

  return !showFooter ? null : (
    <footer id="asu-footer" role="contentinfo">
      <div className="endorsed-row">
        <div className="container flex flex-wrap justify-between">
          <div className="flex items-center">
            <a href="https://www.asu.edu">
              <img
                className="p-block-2 max-h-8rem my-3 h-auto w-full"
                src={asuFooterLogo}
                alt="ASU University Technology Office Arizona State University."
              />
            </a>
          </div>

          <SocialMedia />
        </div>
      </div>

      <div className="innovation-row">
        <div className="innovation-container container flex items-center justify-between font-bold">
          <Contacts />
          <a className="img-link" href="https://www.asu.edu/rankings">
            <img
              src={no1InnovationLockup}
              alt="Number one in the U.S. for innovation. #1 ASU, #2 Stanford, #3 MIT. - U.S. News and World Report, 8 years, 2016-2023"
              width="459"
              height="100"
              loading="lazy"
              decoding="async"
            />
          </a>
        </div>
      </div>

      <div className="colophon-row">
        <div className="container">
          <QuickLinks />
        </div>
      </div>
    </footer>
  );
};

export { Footer };
