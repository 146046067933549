// @ts-check

import { decodeJwt } from 'jose';

import { formatLog } from '../../utils/logger-utils';

/**
 * @param {string} jwtKey
 * @param {number} expiryAdjustmentSeconds
 */
const getTokenFromStorage = async (jwtKey, expiryAdjustmentSeconds = 30) => {
  console.log(...formatLog({ message: 'Verify Token' }));
  console.log(...formatLog({ message: 'getting token from storage' }));
  try {
    const token = localStorage.getItem(jwtKey);

    if (!token) {
      console.log(...formatLog({ message: 'no token in storage' }));
      return;
    }

    const payload = decodeJwt(token);

    // check that token isn't expiring too soon
    const nowSeconds = Math.floor(Date.now() / 1000);
    const exp = payload?.exp || 0;

    if (exp - expiryAdjustmentSeconds < nowSeconds) {
      console.log(
        ...formatLog({
          message: `token is expiring in less than ${expiryAdjustmentSeconds} seconds`,
        }),
      );
      return;
    }

    const remainingTimeSeconds = exp - nowSeconds - expiryAdjustmentSeconds;
    console.log(
      ...formatLog({
        message: `token is ok for another ${remainingTimeSeconds} seconds`,
      }),
    );

    return { token, remainingTimeSeconds };
  } catch (error) {
    console.log(
      ...formatLog({ message: 'error getting token from storage: ' }),
      error,
    );
  }
};

export { getTokenFromStorage };
