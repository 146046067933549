import { FC } from 'react';
import { ProfileMenu } from 'core/components/ProfileMenu';

type Props = { className: string; [x: `data-${string}`]: string };

const RightNavbar: FC<Props> = ({ className, ...dataProps }) => {
  return (
    <ul className={`m-0 flex p-0 ${className || ''}`} {...dataProps}>
      <li className="m-inline-3">
        <a href="/" className="link-navbar">
          Support
        </a>
      </li>
      <li>
        <ProfileMenu />
      </li>
    </ul>
  );
};

export { RightNavbar };
