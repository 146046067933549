import { STermCodes } from 'core/constants/app-constants';
import { fetchData } from 'core/utils/http-utils';

const BASE_URL = '/api/terms';

async function getGraduationTermOptions(): Promise<TermOptionItem[]> {
  const { data } = await fetchData<API.HttpGradTermGetResponse>({
    url: BASE_URL,
    cache: 'force-cache',
  });

  return data
    .filter((gradTerm) => !gradTerm.strm.endsWith(STermCodes.summer))
    .map((gradTerm) => ({
      label: gradTerm.termName,
      value: gradTerm.termName,
      termCode: gradTerm.strm,
    }));
}

export { getGraduationTermOptions };
