import { FC } from 'react';

import { Button, Modal } from 'core/components';
import styles from './index.module.scss';

type Props = {
  showModal: boolean;
  type: 'confirm-delete' | 'confirm-reset';
  title: string;
  body: string;
  btnConfirmText: string;
  onConfirm: () => void;
  onClose: () => void;
};

const icon = {
  'confirm-delete': 'fa-solid fa-trash',
  'confirm-reset': 'fa-solid fa-clock-rotate-left',
};

const ConfirmModal: FC<Props> = ({
  showModal,
  type,
  title,
  body,
  btnConfirmText,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      showModal={showModal}
      showCloseXButton={false}
      width="700px"
      height="485px"
      onClose={onClose}
    >
      <header className={styles.header}>
        <div className={styles['ico-container']}>
          <i className={icon[type]} />
        </div>
        <h2>{title}</h2>
      </header>

      <p className={styles.body}>{body}</p>

      <footer className="flex justify-between self-end">
        <Button onClick={onClose}>Cancel</Button>
        <Button buttonType="warning" onClick={onConfirm}>
          {btnConfirmText}
        </Button>
      </footer>
    </Modal>
  );
};

export { ConfirmModal };
