import { roleList } from 'core/constants/app-constants';
import { fetchData, postData } from '../../utils/http-utils';
import { getViewAsPayload } from '../ui/user-view-as-service';

const JWT_IDP_TOKEN_KEY = 'apps.asu.edu.IDP.token';
const JWT_IDP_CLAIM_IDP_KEY = 'apps.asu.edu.IDP.token.claim';
const JWT_IDP_SEARCH_LIST_KEY = 'apps.asu.edu.IDP.search-list';
const JWT_CURRENT_ROLE_KEY = 'apps.asu.edu.IDP.user.current-role';

const storage = localStorage;

function storeCurrentRole(currentRole: UserProfileRole | null) {
  if (currentRole) {
    storage.setItem(JWT_CURRENT_ROLE_KEY, currentRole);
  } else {
    storage.removeItem(JWT_CURRENT_ROLE_KEY);
  }
}

function recoverCurrentRole(): UserProfileRole | null {
  const currentRole = storage.getItem(JWT_CURRENT_ROLE_KEY) as UserProfileRole;

  if (currentRole && roleList.includes(currentRole)) {
    return currentRole as UserProfileRole;
  }

  return null;
}

async function getUserRoles() {
  try {
    const { data } = await fetchData<API.HttpMeGetResponse>({
      url: '/me',
    });

    const roles = data?.roles ?? [];

    return {
      roles,
    };
  } catch (err) {
    console.error('[getUserRoles]', err);
    return {
      roles: [],
    };
  }
}

function getIDPAuthToken() {
  const token = storage.getItem(JWT_IDP_TOKEN_KEY);

  return { token };
}

function getIDPClaimToken(): API.Token | null {
  const rawData = storage.getItem(JWT_IDP_CLAIM_IDP_KEY);
  const data = rawData ? (JSON.parse(rawData) as API.Token) : null;

  return data;
}

async function requestIDPAuthToken(userRole?: UserProfileRole) {
  const URL = '/token';
  try {
    let payload = null;

    if (userRole) {
      const viewAsParams = getViewAsPayload() || null;
      payload = { ...viewAsParams, role: userRole };
    }

    const { data } = await postData<API.HttpTokenPostResponse>(URL, payload, {
      headers: {
        'Use-ServiceAuth-Token': true,
      },
    });

    // DEBUG: user with more roles
    // DEBUG: data.role = [
    //   'admin',
    //   'platform-admin',
    //   'advisor',
    //   'success-coach',
    //   'student',
    // ];

    storage.setItem(JWT_IDP_CLAIM_IDP_KEY, JSON.stringify(data));
    storage.setItem(JWT_IDP_TOKEN_KEY, data.access_token);

    return data;
  } catch (err) {
    console.error('[requestIDPAuthToken]', err);
  }
  return null;
}

async function searchUsers(searchBy: string) {
  const URL = `/api/users?search=${searchBy}`;
  const response = await fetchData<API.HttpUserSearchGetResponse>({
    url: URL,
    cache: 'force-cache',
  });
  const { data } = response;

  if (Array.isArray(data)) {
    return data;
  }

  return [data];
}

function getLastItems(historyValue: string[]) {
  const MAX_ITEMS = 20;
  return historyValue.slice(0, MAX_ITEMS);
}

function updateUserSearchHistory(searchBy: string) {
  let rawValue = storage.getItem(JWT_IDP_SEARCH_LIST_KEY);
  let historySearch;
  // ========================================
  try {
    historySearch = rawValue ? (JSON.parse(rawValue) as string[]) : [];
  } catch (err) {
    console.error(err);
  }
  historySearch = Array.isArray(historySearch) ? historySearch : [];

  const historyValue = Array.from(new Set([searchBy, ...historySearch]));
  const lastItems = getLastItems(historyValue);

  storage.setItem(JWT_IDP_SEARCH_LIST_KEY, JSON.stringify(lastItems));
}

function readUserSearchHistory() {
  try {
    const rawValue = storage.getItem(JWT_IDP_SEARCH_LIST_KEY);
    const historySearch = rawValue ? (JSON.parse(rawValue) as string[]) : [];

    if (Array.isArray(historySearch)) {
      return getLastItems(historySearch);
    }
  } catch (err) {
    console.error(err);
  }
  return [];
}

export {
  getUserRoles,
  getIDPClaimToken,
  getIDPAuthToken,
  requestIDPAuthToken,
  storeCurrentRole,
  recoverCurrentRole,
  searchUsers,
  readUserSearchHistory,
  updateUserSearchHistory,
};
