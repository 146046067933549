import { createSlice } from '@reduxjs/toolkit';

import { getGraduationTermOptions } from 'core/services/idp/terms-service';
import { createAsyncThunk, dispatchThunkError } from './utils';

const initialState: TermsState = {
  graduationTermOptions: [],
};

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setGraduationTerms: (state, action: ActionOf<TermOptionItem[]>) => {
      state.graduationTermOptions = action.payload;
    },
  },
});

export const getGraduationTermsAsync = createAsyncThunk(
  'termsSlice/getGraduationTermsAsync',
  async (_, { dispatch }) => {
    try {
      const graduationTerms = await getGraduationTermOptions();
      dispatch(setGraduationTerms(graduationTerms));
    } catch (error) {
      dispatchThunkError(dispatch, error, getGraduationTermsAsync);
    }
  },
);

export const { setGraduationTerms } = termsSlice.actions;

export default termsSlice.reducer;
