import { FC, useRef } from 'react';

import { roleList } from 'core/constants/app-constants';
import { useSetCssHeightProperty } from 'core/hooks/use-set-css-property';
import { Chip } from '../Chips/Chip';
import { Dropdown } from '../Dropdown';
import styles from './index.module.scss';

type Props = {
  userName: string;
  currentRole?: UserProfileRole;
  roles?: string[];
  onRoleChange: (roleName: UserProfileRole) => void;
  onResumeUser: () => void;
};

const RoleSwitch: FC<Props> = ({
  userName,
  currentRole,
  roles = roleList,
  onRoleChange,
  onResumeUser,
}) => {
  const refNode = useRef<HTMLDivElement>(null);

  useSetCssHeightProperty({
    refNode,
    cssPropName: '--role-switch-height',
  });

  const roleOptions = roles?.map(
    (role) =>
      ({
        label: role,
        value: role,
      }) as OptionItem<UserProfileRole>,
  );

  return (
    <section
      ref={refNode}
      className={`${styles.container} z-200 bg-gray-2 fixed bottom-0 flex h-16 w-full`}
    >
      <div className="container m-auto flex items-center justify-between">
        <div className="inline-flex items-center gap-2">
          <i className="fa-solid fa-mask" />
          <span>
            Impersonating <strong>{userName}</strong> as a(n)
          </span>
          <Dropdown
            className={styles['role-dropdown']}
            menuPlacement="top"
            isClearable={false}
            value={currentRole}
            options={roleOptions}
            onChange={(e) => {
              onRoleChange(e.value as UserProfileRole);
            }}
          />
        </div>
        <Chip
          className="font-bold"
          data-type="button-chip"
          role="button"
          tabIndex={0}
          onClick={onResumeUser}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.currentTarget.click();
            }
          }}
        >
          <i className="fa-solid fa-xmark" />
          <span>Resume as self</span>
        </Chip>
      </div>
    </section>
  );
};

export { RoleSwitch };
