import { FC } from 'react';

import styles from './index.module.scss';

type Props = {
  title: string;
  message: React.ReactNode;
};

const DotsLoader: FC<Props> = ({ title, message }) => {
  return (
    <div className={styles.container}>
      <div className="loader">
        <div className="loader-animator">
          <div className="dot dot-1"></div>
          <div className="dot dot-2"></div>
          <div className="dot dot-3"></div>
        </div>

        <p className="loader-message">
          <strong className="message-title">{title}</strong>
          <span className="message-description">{message}</span>
        </p>
      </div>
    </div>
  );
};

export { DotsLoader };
