import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Navbar } from 'core/components';
import { AlertProvider, LoaderProvider } from 'core/context';

const PageLayout: FC = () => {
  return (
    <>
      <Navbar />
      <AlertProvider>
        <main className="app">
          <LoaderProvider>
            <Outlet />
          </LoaderProvider>
        </main>
      </AlertProvider>
      <Footer />
    </>
  );
};

export { PageLayout };
