import { AriaAttributes, FC, useMemo, useState } from 'react';

import { StarToggleSVG } from '../Icons';
import styles from './index.module.scss';

type Props = {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
  onClick?: () => void;
  onMouseOver?: React.MouseEventHandler<HTMLLabelElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLLabelElement>;
} & AriaAttributes;

type ReactMouseEvent = React.MouseEvent<HTMLLabelElement, MouseEvent>;

const StarToggle: FC<Props> = ({
  className,
  checked,
  disabled = false,
  readonly = false,
  label,
  onClick,
  onMouseOver,
  onMouseLeave,
  ...props
}) => {
  const id = useMemo(
    () => `star-toggle-${String(Math.random() * 1000).replace('.', '-')}`,
    [],
  );
  const [mouseOver, setMouseOver] = useState(false);

  const attributes =
    readonly || disabled
      ? {}
      : {
          onMouseOver: (e: ReactMouseEvent) => {
            !checked && setMouseOver(true);
            onMouseOver?.(e);
          },
          onMouseLeave: (e: ReactMouseEvent) => {
            setMouseOver(false);
            onMouseLeave?.(e);
          },
          onKeyDown: (e: React.KeyboardEvent) => {
            if (disabled) return;

            if (e.key === 'Enter' || e.key === 'Space') {
              e.stopPropagation();
              onClick?.();
            }
          },
        };

  return (
    <label
      tabIndex={0}
      htmlFor={id}
      role="switch"
      className={`${styles.container} ${className} m-0 flex items-center gap-1 p-0`}
      {...props}
      aria-checked={checked}
      aria-disabled={disabled}
      aria-readonly={readonly}
      {...attributes}
    >
      <span className="flex">
        <input
          id={id}
          type="checkbox"
          className="hidden"
          onClick={disabled ? undefined : onClick}
        />
        <StarToggleSVG data-on-hover data-checked={checked || mouseOver} />
      </span>
      {label && <span>{label}</span>}
    </label>
  );
};

export { StarToggle };
