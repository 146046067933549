/**
 * @param {Auth0ClientService} client
 */
const setClientInstance = (client) => {
  window.__ASU_AUTH0_DEVTOOLS__ = {
    authClientInstance: client,
  };
};

const getClientInstance = () => {
  return window.__ASU_AUTH0_DEVTOOLS__.authClientInstance;
};

/**
 * Get/Set a global instance of the Auth0Client.
 * It used to retrieve the JWT token and trigger
 * the SSO LOGIN when the token is expired.
 */
const authClient = {
  setClientInstance,
  getClientInstance,
};

export { authClient };
