import { createBlobUrl, sendMessage, stringifyCode } from './utils';

type Dependency = () => unknown;
type Setting =
  | {
      /**
       * @example
       * const worker = new Worker(new URL('./myRelativePath/myWorker.ts', import.meta.url)
       */
      worker: Worker;
      embedWorker?: never;
    }
  | {
      embedWorker: {
        script: () => void;
        name?: string;
        deps?: Dependency[];
      };
      worker?: never;
    };

class WorkerManager<TPostMessage = unknown> {
  listeners: (() => void)[] = [];
  sharedEventAdded: boolean = false;
  worker: Worker;

  constructor({ worker, embedWorker }: Setting) {
    if (worker) {
      this.worker = worker;
    } else {
      const { script, name, deps } = embedWorker;
      const depsCode = deps ? deps.map((dep) => dep.toString()) : [];
      const mainCode = stringifyCode(script);
      const codeUrl = createBlobUrl([...depsCode, mainCode]);

      this.worker = new Worker(codeUrl, {
        name,
      });
    }
  }

  postMessage(message: TPostMessage) {
    this.worker.postMessage(message);
  }

  postMessageAsync(message: TPostMessage) {
    return sendMessage(message, this.worker);
  }

  addEventListener<K extends keyof WorkerEventMap>(
    type: K,
    listener: (this: Worker, ev: WorkerEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ) {
    this.worker.addEventListener(type, listener, options);
  }

  /**
   * Appends an event listener for events whose type attribute value is type.
   *
   * It differs from `addEventListener`, because it appends an event listener
   * only once for the same object instance; no matter how many times is called.
   */
  addSharedEventListener<K extends keyof WorkerEventMap>(
    type: K,
    listener: (this: Worker, ev: WorkerEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions,
  ): void {
    if (!this.sharedEventAdded) {
      this.listeners.push(listener as () => void);
      this.worker.addEventListener(type, listener, options);
      this.sharedEventAdded = true;
    }
  }

  removeAllEventListener<K extends keyof WorkerEventMap>(type: K) {
    this.sharedEventAdded = false;
    this.listeners.forEach((listener) => {
      this.worker.removeEventListener(type, listener);
    });
    this.listeners = [];
  }
}

export { WorkerManager };
