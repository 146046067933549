import React, { FC } from 'react';

type Props = GenericFieldProps & {
  list: ListItem[];
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

function makeId(id: string) {
  return `option-item-${id}`;
}

const OptionListField: FC<Props> = ({
  label,
  value,
  list,
  error,
  required,
  onChange,
}) => {
  const name = 'option-select-' + label.replaceAll(' ', '-');

  return (
    <fieldset>
      <legend>
        <strong>{label}</strong>
      </legend>
      {error && (
        <small className="invalid-feedback is-invalid">
          <span title="Alert" className="fa fa-icon fa-exclamation-triangle" />
          {error}
        </small>
      )}

      {list.map((item) => (
        <div key={makeId(item.id)} className="form-check">
          <input
            required={required}
            className="form-check-input"
            type="radio"
            name={name}
            id={makeId(item.id)}
            value={item.value ?? item.text}
            checked={item.value === value || item.text === value}
            onChange={onChange}
          />
          <label className="font-normal" htmlFor={makeId(item.id)}>
            {item.text}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export { OptionListField };
