import { FC } from 'react';

import styles from './index.module.scss';

const computePadding = (value: number | string) =>
  String(value).length === 1 || String(value).length > 2 ? 'p-inline-2' : '';

type Props = React.PropsWithChildren<
  {
    type?: 'button-chip';
    shape?: 'rect' | 'circle';
    roundSize?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';
    textColorClassName?: `text-${string}`;
    bgColorClassName?: `bg-${string}`;
    autoPaddingAdjust?: boolean;
  } & React.ComponentPropsWithRef<'span'>
>;

const Chip: FC<Props> = ({
  className = '',
  children,
  type,
  shape = 'rect',
  roundSize,
  autoPaddingAdjust = false,
  bgColorClassName = 'bg-gray-200',
  textColorClassName = 'text-gray-700',
  ...props
}) => {
  const paddingClass =
    autoPaddingAdjust &&
    (typeof children === 'string' || typeof children === 'number')
      ? computePadding(children)
      : '';

  const roundClass =
    shape === 'circle'
      ? 'aspect-square rounded-full '
      : roundSize
      ? `rounded-${roundSize}`
      : 'rounded';

  return (
    <span
      data-type={type}
      className={`${styles.container} ${className} ${paddingClass} ${roundClass}
       inline-flex h-fit w-fit items-center justify-center gap-2
       ${bgColorClassName} text-sm ${textColorClassName}`}
      {...props}
    >
      {children}
    </span>
  );
};

export { Chip };
