/**
 * Sends the specified message to the web worker
 * @param message The message to send
 * @param to The worker to send the message to
 */
export const sendMessage = <T>(
  message: unknown,
  to: Worker,
): Promise<MessageEvent<T>> =>
  new Promise(function (resolve, reject) {
    to.onmessage = function (event) {
      if (event.data?.action === 'error') {
        reject(new Error(event.data));
      } else {
        resolve(event);
      }
    };

    to.postMessage(message);
  });

/**
 * Stringify the scriptFunction and brings out the code in the bracket "{...}"
 * @param {() => void} scriptFunction
 */
export function stringifyCode(scriptFunction: () => void) {
  const rawCode = scriptFunction.toString();
  const mainCode = rawCode.substring(
    rawCode.indexOf('{') + 1,
    rawCode.lastIndexOf('}'),
  );

  return mainCode;
}

/**
 * Convert the code into a raw data
 * A url is made out of the blob object and we're good to go
 * @param {BlobPart[]} blobParts
 */
export function createBlobUrl(blobParts: BlobPart[]) {
  const blob = new Blob(blobParts, {
    type: 'application/javascript',
  });

  const codeUrl = URL.createObjectURL(blob);

  return codeUrl;
}
