const JWT_IDP_VIEW_AS_ACTIVE_KEY = 'apps.asu.edu.IDP.ViewAs.active';
const JWT_IDP_VIEW_AS_ROLE_KEY = 'apps.asu.edu.IDP.ViewAs.role';
const JWT_IDP_VIEW_AS_USER_NAME_KEY = 'apps.asu.edu.IDP.ViewAs.userName';
const JWT_IDP_VIEW_AS_EMPLID_KEY = 'apps.asu.edu.IDP.ViewAs.emplid';
const JWT_IDP_VIEW_AS_USER_KEY = 'apps.asu.edu.IDP.ViewAs.user';

const viewAsStorage = sessionStorage;

function isViewAsActive() {
  const viewAsActive =
    viewAsStorage.getItem(JWT_IDP_VIEW_AS_ACTIVE_KEY) === 'true';
  return viewAsActive;
}

function startViewAsUser({
  viewAsUserName,
  viewAsUserRole,
  viewAsEmplid,
}: {
  viewAsUserName: string;
  viewAsUserRole: UserProfileRole;
  viewAsEmplid: string;
}) {
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_ACTIVE_KEY, 'true');
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_USER_NAME_KEY, viewAsUserName);
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_EMPLID_KEY, viewAsEmplid);
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_ROLE_KEY, viewAsUserRole);
}

function stopViewAsUser() {
  for (const key of Object.keys(viewAsStorage)) {
    if (key.includes('.ViewAs.')) {
      viewAsStorage.removeItem(key);
    }
  }
}

function getViewAsPayload() {
  const viewAsActive = isViewAsActive();
  const viewAsSubject = viewAsStorage.getItem(JWT_IDP_VIEW_AS_USER_NAME_KEY);
  const viewAsRole = viewAsStorage.getItem(JWT_IDP_VIEW_AS_ROLE_KEY);
  let payload = {};

  if (viewAsActive && viewAsSubject) {
    payload = {
      viewAsRole,
      viewAsSubject,
    };
  }

  return payload;
}

function storeViewAsUser(viewAsUser: UserViewAs) {
  viewAsStorage.setItem(JWT_IDP_VIEW_AS_USER_KEY, JSON.stringify(viewAsUser));
}

function recoverViewAsUser() {
  const rawState = viewAsStorage.getItem(JWT_IDP_VIEW_AS_USER_KEY) || '';
  const isValidJson = rawState.startsWith('{') && rawState.endsWith('}');

  const localState = (
    isValidJson ? JSON.parse(rawState) : {}
  ) as Partial<UserViewAs>;

  return localState;
}

export {
  startViewAsUser,
  stopViewAsUser,
  getViewAsPayload,
  isViewAsActive,
  storeViewAsUser,
  recoverViewAsUser,
};
