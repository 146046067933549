const TERM_COUNTER = 13;

const ACTIVE_PLAN_DEFAULT_NAME = 'My Plan';

const RoleName = {
  STUDENT: 'student',
  ADVISOR: 'advisor',
  COACH: 'success-coach',
  ADMIN: 'admin',
  PLATFORM: 'platform-admin',
  NONE: '',
} as const;

const roleList = Object.values(RoleName);

const MOBILE_BREAK_POINT = '840px';

const HttpCode = {
  SUCCESS_CODE: 200,
  IN_PROGRESS_CODE: 202,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  CONNECTION_CLOSED_BY_CLIENT: 499,
} as const;

const StatusLabel: Record<API.PlanData.ClassStatus, string> = {
  'in-progress': 'In progress',
  'not-started': 'Registered',
  completed: 'Completed',
  planned: 'Planned',
} as const;

const progressStatusList = ['in-progress', 'not-started', 'completed'];

const NONE_VALUE = 'None';

const NO_REPORT = 'NR';

const GENERAL_ELECTIVES = 'General Electives';

const STermCodes = {
  spring: '1',
  summer: '4',
  fall: '7',
} as const;

const ClassTags = {
  DROP_PLANNED: 'Class drop planned',
  WITHDRAW_PLANNED: 'Withdrawal planned',
} as const;

const RequisiteType = {
  COURSE_REQUISITE: 'CourseRequisite',
  CONDITION_REQUISITE: 'ConditionRequisite',
} as const;

const CourseLabels = {
  ELECTIVE: 'Elective',
  UNUSED: 'Unused',
  MULTIPLE_COURSE_OPTIONS: 'Required Course - multiple course options',
  SINGLE_COURSE_OPTION: 'Required Course - single course option',
} as const;

type CourseLabelsValues = ValueOf<typeof CourseLabels>;

const AMPER_SAND = '\u2014';

const buildInfo = {
  REQUESTING_DEGREE_AUDIT: {
    text: 'Collecting data',
    icon: 'fa-solid fa-gear text-gray-500 rotate-animate',
  },
  SEQUENCING: {
    text: 'Sequencing',
    icon: 'fa-solid fa-gear text-gray-700 rotate-animate',
  },
  COMPLETE: {
    text: 'Creation completed',
    icon: 'fa-solid fa-circle-check text-success',
  },
  ERROR: {
    text: 'Creation failed',
    icon: 'fa-solid fa-circle-xmark text-error',
  },
  DEFAULT: {
    text: '',
    icon: 'fa-solid fa-triangle-exclamation text-warning-200',
  },
} as const;

export const SUFFIX_OTHER_VERSION = '/v1';

export {
  ACTIVE_PLAN_DEFAULT_NAME,
  AMPER_SAND,
  buildInfo,
  HttpCode,
  progressStatusList,
  RoleName,
  roleList,
  StatusLabel,
  GENERAL_ELECTIVES,
  MOBILE_BREAK_POINT,
  NONE_VALUE,
  TERM_COUNTER,
  STermCodes,
  ClassTags,
  RequisiteType,
  NO_REPORT,
  CourseLabels,
  type CourseLabelsValues,
};
