import React, { FC } from 'react';

import styles from './index.module.scss';

type Props = React.PropsWithChildren<
  {
    buttonType?: 'primary' | 'secondary' | 'warning' | 'generic';
    size?: 'sm' | 'md' | 'lg' | 'compact';
    fullWidth?: boolean;
    animatePress?: boolean;
    overrideStyle?: boolean;
  } & React.ComponentPropsWithRef<'button'>
>;

const Button: FC<Props> = React.forwardRef(
  (
    {
      className = '',
      children,
      size,
      buttonType,
      fullWidth,
      animatePress,
      overrideStyle,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      className={`${styles.container} ${className}`}
      type="button"
      data-size={size}
      data-type={buttonType}
      data-full-width={fullWidth}
      data-animate-press={animatePress}
      data-override-style={overrideStyle}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      {children}
    </button>
  ),
);

export { Button };
