// @ts-check
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// local services
import {
  Auth0ClientService,
  AuthError,
  getTokenFromStorage,
  hasAuthParams,
} from '../sso';
import { authSettings } from '../sso/constants/auth-settings';
import { recoverState } from '../sso/services/auth-local-state-service';
import { actionTypes, initialAuthState, reducer } from '../store';
import { authClient } from '../utils/auth-client';
import { getSSOName } from '../utils/get-sso-name';
import { formatLog } from '../utils/logger-utils';
import { isAsyncFunction } from '../utils/promise-utility';
import { AuthContext } from '.';

/**
 * @typedef {{
 *    jwt: AppJwtToken | undefined,
 *    action: "CREATE_TOKEN" | "REFRESH_TOKEN"
 * }} AuthTokenEvent
 * @typedef {( event: AuthTokenEvent) => Promise<void> | void } AuthTokenEventHandler
 */

/**
 * @template TClaim
 *
 * @param {PropsWithChildren<{
 *  authProps: Partial<AuthContextValue<TClaim>>
 *  onTokenUpdate?: AuthTokenEventHandler
 *  onRedirect?: () => void
 * }>} props
 */
export function AuthProvider({
  children,
  authProps = {},
  onRedirect,
  onTokenUpdate,
}) {
  let navigate = useNavigate();

  /** @type {[AuthState, (action: AuthAction) => void]} */
  const [state, dispatch] = useReducer(reducer, {
    ...initialAuthState,
    ...authProps.state,
  });

  const didInitialize = useRef(false);

  /** @type {React.MutableRefObject<NodeJS.Timeout | string | number | undefined>} */
  const timerRef = useRef(undefined);

  const { customSettings, getUserRoles, Loader } = authProps;
  const notAuthorizedUrl = customSettings?.notAuthorizedUrl;

  const [client] = useState(
    () => new Auth0ClientService({ ...authSettings, ...customSettings }),
  );
  client.onRedirect = onRedirect;

  useEffect(() => {
    authClient.setClientInstance(client);
  }, [client]);

  useEffect(() => {
    if (didInitialize.current) {
      return;
    }
    didInitialize.current = true;

    /**
     * @param {AuthTokenEvent} event
     */
    const notifyTokenUpdate = async (event) => {
      if (isAsyncFunction(onTokenUpdate)) {
        await onTokenUpdate?.(event);
      } else {
        onTokenUpdate?.(event);
      }
    };

    const startRefreshTokenTimeout = (remainingTimeSeconds = 60 * 9) => {
      // compute seconds left to the next scheduled request
      const timeOutSeconds = remainingTimeSeconds;
      const TIME_OUT = timeOutSeconds * 1000;

      // compute next scheduled time request
      const now = new Date();
      now.setSeconds(now.getSeconds() + timeOutSeconds);
      const scheduledTime = now.toLocaleTimeString();

      console.log(
        ...formatLog({
          action: 'Timer-Start',
          message:
            `Next token will be requested in ${timeOutSeconds} in second,` +
            ` at ${scheduledTime}`,
        }),
      );

      timerRef.current = setTimeout(async () => {
        try {
          await client.requestRefreshToken();
          const jwt = await getTokenFromStorage(authSettings.tokenKey);

          startRefreshTokenTimeout(jwt?.remainingTimeSeconds);
          await notifyTokenUpdate({ jwt, action: 'REFRESH_TOKEN' });
        } catch (error) {
          dispatch({ type: actionTypes.ERROR, error: error });
        }
      }, TIME_OUT);
    };

    (async () => {
      if (state.errorBlocker) {
        return;
      }

      console.log(
        ...formatLog({
          action: 'Mount',
          message: `Checking for auth params`,
        }),
      );

      try {
        let returnUrl = '';
        const localState = recoverState();

        let isAuthenticated =
          localState?.isAuthenticated || state.isAuthenticated;
        // =====================================================================
        if (hasAuthParams()) {
          const { returnTo } = await client.requestServiceAuthToken();
          dispatch({ type: actionTypes.SET_AUTHENTICATED });
          dispatch({ type: actionTypes.STORE_AUTH_LOCAL_STORAGE });
          isAuthenticated = true;

          if (returnTo) {
            returnUrl = returnTo;
            // If lands on "401 not authorized" page prior the auth flow starts
            // it reset return to HomePage = "/"
            if (returnUrl === notAuthorizedUrl) {
              returnUrl = '/'; // Home page
            }
            navigate(returnUrl);
          }
        }
        // =====================================================================
        if (isAuthenticated === false) {
          client.loginWithRedirect();
          return;
        }
        // =====================================================================
        const jwt = await client.checkSession();
        if (!jwt) return;
        startRefreshTokenTimeout(jwt.remainingTimeSeconds);

        await notifyTokenUpdate({ jwt, action: 'CREATE_TOKEN' });
        // =====================================================================
        const user = await client.getUser();
        const ssoName = getSSOName();
        // =====================================================================
        dispatch({ type: actionTypes.RECOVER_AUTH_LOCAL_STORAGE });
        dispatch({
          type: actionTypes.INITIALIZED,
          user,
          ssoName,
          returnUrl,
        });
        // =====================================================================
        const claim = await client.getTokenClaim();
        dispatch({
          type: actionTypes.SET_CLAIM,
          claim,
        });
        // =====================================================================
      } catch (error) {
        console.log(
          ...formatLog({
            action: 'Error',
            message: `Checking for auth params`,
          }),
          error,
        );

        dispatch({
          type: actionTypes.ERROR,
          error,
          errorBlocker: true,
        });

        if (
          error instanceof AuthError ||
          // @ts-ignore
          error.message.includes('status code 400')
        ) {
          navigate('/');
        }
      }
    })();
  }, [
    client,
    state.errorBlocker,
    state.isAuthenticated,
    notAuthorizedUrl,
    navigate,
    onTokenUpdate,
    onRedirect,
  ]);

  /** Unmount component. */
  useEffect(() => {
    /** Use the "return" block to trigger a cleaning action
        once a component is unmount.  */
    return () => {
      clearTimeout(timerRef.current);
      console.log(
        ...formatLog({
          action: 'Timer-Stop',
        }),
      );
    };
  }, []);

  const loadUserRoles = useCallback(async () => {
    if (!getUserRoles) return;

    dispatch({
      type: actionTypes.SET_USER_ROLES,
      userRoles: {
        loading: true,
        completed: false,
      },
    });

    const roles = await getUserRoles();

    dispatch({
      type: actionTypes.SET_USER_ROLES,
      userRoles: {
        roles,
        loading: false,
        completed: true,
      },
    });
  }, [getUserRoles]);

  const loginWithRedirect = useCallback(
    () => client.loginWithRedirect(),
    [client],
  );

  const logout = useCallback(() => {
    client.logout();
    dispatch({ type: actionTypes.LOGOUT });
  }, [client]);

  return (
    <AuthContext.Provider
      value={
        /** @type {AuthContextValue} */
        ({
          state,
          customSettings,
          loginWithRedirect,
          logout,
          loadUserRoles,
          getUserRoles,
          Loader,
        })
      }
    >
      {children}
    </AuthContext.Provider>
  );
}
