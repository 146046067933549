import { RoleName } from './app-constants';

const appRouterUrl = {
  // ============ Global urls ============
  HOME: '/',
  NOT_AUTHORIZED: '/401-user-not-authorized',
  NOT_FOUND: '/404-resource-not-found',
  LOG_OUT: '/logout',

  // ============ Student urls ============
  STUDENT_PROFILE: '/student-profile',
  STUDENT_DASHBOARD: '/student-dashboard',
  PLAN_DETAIL: '/plan-detail',
  PLAN_DETAIL_BY_PLAN_ID: '/plan-detail/:planId',
  PLAN_VIEW_BY_EMPLID_PLAN_ID: '/plan-detail/view/:searchEmplId/:planId',

  /** @deprecated - This is a modal popup. This is going to be removed */
  PLAN_SET_UP: '/plan-setup',
  /** @deprecated - not in scope for v1 */
  PLANS_LIST: '/plan-list',
  /** @deprecated - not in scope for v1 */
  COMPARISON_LIST: '/comparison-list',

  // ============ advisor/coach urls ============
  /** @deprecated - use ADMIN_DASHBOARD */
  ADVISOR_DASHBOARD: '/advisor-dashboard',
  /** @deprecated - use ADMIN_DASHBOARD */
  COACH_DASHBOARD: '/coach-dashboard',

  // ============ ADMIN urls ============
  ADMIN_PROFILE_VIEW: '/admin-profile-view',
  ADMIN_PROFILE_VIEW_BY_ID: '/admin-profile-view/:searchEmplId',
  ADMIN_DASHBOARD: '/admin-dashboard',

  /** @deprecated - not in scope for v1 */
  ADMIN_SETTINGS: '/admin-settings',
  /** @deprecated - not in scope for v1 */
  ADMIN_REPORTS: '/admin-reports',
} as const;

export type RouterUrlValue = (typeof appRouterUrl)[keyof typeof appRouterUrl] &
  string;

const parsePlanDetailViewRoute = (searchEmplId: string, planId: string) => {
  let url = appRouterUrl.PLAN_VIEW_BY_EMPLID_PLAN_ID.replace(
    ':searchEmplId',
    searchEmplId,
  );
  url = url.replace(':planId', planId);

  return url;
};

/** @deprecated - Profile page is one single page. This is going to be removed */
const profileRouterUrl = {
  CONTACT_INFO: 'contact-info',
  DEGREE_PROGRAMS: 'degree-programs',
  ADVISOR_INFO: 'advisor-info',
} as const;

const startPageByRole: Record<string, string> = {
  [RoleName.PLATFORM]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.ADMIN]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.ADVISOR]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.COACH]: appRouterUrl.ADMIN_DASHBOARD,
  [RoleName.STUDENT]: appRouterUrl.STUDENT_DASHBOARD,
  [RoleName.NONE]: appRouterUrl.NOT_FOUND,
};

export {
  appRouterUrl,
  profileRouterUrl,
  startPageByRole,
  parsePlanDetailViewRoute,
};
