import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getStudentProfile,
  saveStudentProfile,
} from '../../services/idp/student-profile-service';
import { setHttpError } from './sharedSlice';
import { dispatchThunkError, pickEmplid } from './utils';

const initialStateValidator: StudentProfileState['validator'] = {
  canValidate: true,
  isValid: true,
  alternateEmail: true,
  mode: true,
  timeZone: true,
  startTime: true,
  endTime: true,
};

const initialState: StudentProfileState = {
  loading: false,
  data: {
    emplid: '',
    firstName: '',
    lastName: '',
    preferredName: '',
    email: '',
    phone: '',
    preferredCommunication: {
      alternatePhone: '',
      alternateEmail: '',
      mode: '',
      timeZone: '',
      startTime: '',
      endTime: '',
    },
    declaredDegreePrograms: {
      declaredMajors: [],
      declaredCerts: [],
      declaredMinors: [],
    },

    advisors: [],
  },
  isViewMode: true,
  validator: initialStateValidator,
};

export const studentProfileSlice = createSlice({
  name: 'studentProfile',
  initialState,
  reducers: {
    setStudentProfileLoading: (state, action: { payload: boolean }) => {
      state.loading = action.payload;
    },
    setStudentProfile: (
      state,
      action: { payload: API.StudentData.Profile },
    ) => {
      state.data = action.payload;
    },
    setViewMode: (state, action: { payload: boolean }) => {
      state.isViewMode = action.payload;
    },
    setCanValidate: (state, action: { payload: boolean }) => {
      state.validator.canValidate = action.payload;
    },
    resetValidator: (state) => {
      state.validator = initialStateValidator;
    },
    setValid: (state, action: { payload: boolean }) => {
      state.validator.isValid = action.payload;
    },
    setContactDetail: (
      state,
      action: {
        payload: Partial<API.StudentData.PreferredCommunication>;
      },
    ) => {
      state.data.preferredCommunication = {
        ...state.data.preferredCommunication,
        ...action.payload,
      };
    },
    setContactValidator: (
      state,
      action: { payload: Partial<StudentProfileState['validator']> },
    ) => {
      state.validator = {
        ...state.validator,
        ...action.payload,
      };
      state.validator.isValid = Object.values(state.validator).every(
        (value) => value === true,
      );
    },
  },
});

export const getStudentProfileAsync = createAsyncThunk(
  'studentProfileSlice/getStudentProfileAsync',
  async (payload, { dispatch, getState }) => {
    dispatch(setStudentProfileLoading(true));

    try {
      const emplid = pickEmplid(getState());
      const data = await getStudentProfile(emplid);
      const initData = initialState.data;
      dispatch(
        setStudentProfile({
          ...data,
          advisors: data.advisors || initData.advisors,
          preferredCommunication:
            data?.preferredCommunication || initData.preferredCommunication,
          declaredDegreePrograms:
            data.declaredDegreePrograms || initData.declaredDegreePrograms,
        }),
      );
    } catch (error) {
      dispatchThunkError(dispatch, error, getStudentProfileAsync);
    }
    dispatch(setStudentProfileLoading(false));
  },
);

export const saveStudentProfileAsync = createAsyncThunk(
  'studentProfileSlice/saveStudentProfileAsync',
  async (payload, { dispatch, getState }) => {
    dispatch(setStudentProfileLoading(true));
    const state = getState() as AppState;

    try {
      const emplid = pickEmplid(getState());
      const response = await saveStudentProfile(
        state.studentProfile.data.preferredCommunication,
        emplid,
      );
      return response.status === 200;
    } catch (error) {
      dispatch(
        setHttpError({
          httpError: (error as HTTPError).message,
          sourceAction: saveStudentProfileAsync.typePrefix,
        }),
      );
    } finally {
      dispatch(setStudentProfileLoading(false));
    }
  },
);

export const {
  resetValidator,
  setStudentProfileLoading,
  setStudentProfile,
  setCanValidate,
  setContactDetail,
  setContactValidator,
  setValid,
  setViewMode,
} = studentProfileSlice.actions;

export default studentProfileSlice.reducer;
