import { FC } from 'react';

type Props = GenericFieldProps;

const OutputField: FC<Props> = ({ label, value, defaultValue }) => {
  return (
    <div className="mb-2">
      <label className="mb-0 font-bold">{label}</label>
      <div>{value || defaultValue}</div>
    </div>
  );
};

export { OutputField };
